/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { baseApi } from 'api/base'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { Country, SecurityQuestion, State } from 'models/account'
import { Settings, SignUpFields, SupportRequestPayload } from 'models/app'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { parseJSON } from 'helpers/hooks'

/**********************************************************************************************************
 *   APP API
 **********************************************************************************************************/
export const appAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getToken: builder.query<null, void>({
            query: () => 'client/csrf-token'
        }),
        initialiseApplication: builder.query<{ settings: Settings }, void>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                await baseQuery('client/csrf-token')

                const fetchSettings = async () => {
                    return baseQuery('client/settings') as QueryReturnValue<
                        { data: Settings; meta: FetchBaseQueryMeta },
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >
                }

                function getExistingSettings() {
                    const existingSettingsOrBadValue = parseJSON<{ data: Settings }>(localStorage.getItem('mca_theme'))

                    if (
                        typeof existingSettingsOrBadValue !== 'object' ||
                        existingSettingsOrBadValue === null ||
                        Object.keys(existingSettingsOrBadValue).length <= 0
                    ) {
                        return null
                    }

                    return existingSettingsOrBadValue
                }

                const existingSettings = getExistingSettings()

                if (existingSettings) {
                    return {
                        data: {
                            settings: existingSettings.data
                        }
                    }
                }

                const newSettings = await fetchSettings()
                localStorage.setItem('mca_theme', JSON.stringify(newSettings.data))

                if (newSettings.error) {
                    return { error: newSettings.error }
                }

                return {
                    data: {
                        settings: newSettings.data.data
                    }
                }
            },
            providesTags: ['InitialiseApplication']
        }),
        register: builder.mutation<void, SignUpFields>({
            query: (credentials) => ({
                url: 'client/register',
                method: 'POST',
                body: credentials
            })
        }),
        securityQuestions: builder.query<Array<SecurityQuestion>, void>({
            query: () => 'client/questions',
            transformResponse: (baseQueryReturnValue: { data: Array<SecurityQuestion> }) => baseQueryReturnValue.data
        }),
        countries: builder.query<Array<Country>, { type: 'all' | 'restricted' }>({
            query: (params) => ({
                url: 'client/countries',
                params
            }),
            transformResponse: (baseQueryReturnValue: { data: Array<Country> }) => baseQueryReturnValue.data
        }),
        countryStates: builder.query<Array<State>, string>({
            query: (code) => `client/countries/${code}/states`,
            transformResponse: (baseQueryReturnValue: { data: Array<State> }) => baseQueryReturnValue.data
        }),
        SupportRequest: builder.mutation<void, SupportRequestPayload>({
            query: ({ token, ...rest }) => ({
                url: 'client/support',
                method: 'POST',
                body: {
                    'g-recaptcha-response': token,
                    ...rest
                }
            })
        })
    })
})

export const {
    useGetTokenQuery,
    useInitialiseApplicationQuery,
    useRegisterMutation,
    useSecurityQuestionsQuery,
    useCountriesQuery,
    useCountryStatesQuery
} = appAPI
