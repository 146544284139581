/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { css } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Theme } from 'nxui/src'
import { TButtonBaseProps } from '../base'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const AnchorStyles = {
    Color: css<{ color?: TButtonBaseProps['color'] }>`
        ${({ color }) => {
            switch (color) {
                case 'error':
                    return css`
                        color: ${({ theme }) => theme['error--100']};

                        .icon {
                            color: ${({ theme }) => theme['error--100']};
                        }

                        &:hover {
                            color: ${({ theme }) => theme['error--200']};

                            .icon: {
                                color: ${({ theme }) => theme['error--200']};
                            }
                        }
                    `

                case 'alternate':
                    return css`
                        color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['white--100'], '0.6')};

                        .icon {
                            color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['white--100'], '0.6')};
                        }

                        &:hover {
                            color: ${({ theme }) => theme['white--100']};

                            .icon: {
                                color: ${({ theme }) => theme['white--100']};
                            }
                        }
                    `

                case 'select':
                    return css`
                        justify-content: center;
                        height: 32px;
                        padding: 0 12px;
                        margin: 0;
                        border-radius: 8px;

                        color: ${({ theme }) => theme['primary--100']};
                        background-color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['primary--100'], '0.1')};

                        .icon {
                            color: ${({ theme }) => theme['primary--100']};
                        }

                        &:hover {
                            background-color: ${({ theme }) => Theme.Functions.hexToRGBA(theme['primary--100'], '0.2')};
                        }
                    `

                case 'secondary':
                    return css`
                        color: ${({ theme }) => theme['text--200']};

                        .icon {
                            color: ${({ theme }) => theme['text--200']};
                        }

                        &:hover {
                            color: ${({ theme }) => theme['text--100']};

                            .icon: {
                                color: ${({ theme }) => theme['text--100']};
                            }
                        }
                    `
                case 'primary':
                default:
                    return css`
                        color: ${({ theme }) => theme['primary--100']};

                        .icon {
                            color: ${({ theme }) => theme['primary--100']};
                        }

                        &:hover {
                            color: ${({ theme }) => theme['primary--200']};

                            .icon: {
                                color: ${({ theme }) => theme['primary--200']};
                            }
                        }
                    `
            }
        }}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/
