import { AdjustmentsHorizontalIcon, PencilIcon, PlusCircleIcon, ShoppingCartIcon, TicketIcon, TrashIcon } from '@heroicons/react/20/solid'
import { ArrowsUpDownIcon, ChevronDownIcon, GlobeAltIcon, ServerIcon } from '@heroicons/react/24/outline'
import { Form } from 'formik'
import { motion } from 'framer-motion'
import { Weight } from 'models/enums'
import { TColors } from 'models/theme'
import { Anchor, Badge, Button, Card, Fade, Tabs, Theme } from 'nxui/src'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Shop = {
    Base: styled.div`
        display: flex;
        flex-flow: column nowrap;
        align-self: flex-start;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;

        ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-sm'])} {
            padding: 0;
        }
    `,
    Transition: styled(Fade)`
        display: block;
        height: 100%;
        width: 100%;
    `,
    Tabs: styled.div`
        all: unset;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 2rem;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            flex-direction: column;
        }
    `,
    Tab: styled.div<{ active: boolean }>`
        all: unset;
        cursor: pointer;
        position: relative;
        display: block;
        min-width: fit-content;
        padding: 0.5rem 0rem 1.75rem;
        margin: 0 2rem;
        font-size: 1.4rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--100'], '0.8')};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        ${Theme.Functions.animation('color', 300)};

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            margin-left: 0rem;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-left: 0rem;
        }
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            &:last-of-type {
                margin-left: 0rem;
            }
        }

        &:hover {
            color: ${(props) => props.theme['text--200']};
        }

        ${(props) =>
            props.active &&
            `
               color: ${props.theme['info--100']};
               border-bottom: 2px solid ${props.theme['info--100']};

               &:hover {
                   color: ${props.theme['info--100']};
               }
           `}
    `,
    TabLoader: styled.div`
        margin-bottom: 13px;
    `,
    Content: styled.section`
        display: flex;
        gap: 20px;
        ${Theme.Functions.hideScrollbar()}
        overflow: unset;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            padding: 0px;
            flex-direction: column;
        }

        > div {
            width: 100%;
        }
    `,
    Title: styled.h1`
        font-size: 4.8rem;
        padding: 4rem 0rem 2.5rem;
        letter-space: 1.6px;
        ${Theme.Functions.setFont('Inter', Weight.Bold)};
    `,
    Summary: {
        Base: styled(Card)``
    },
    Browse: {
        Base: styled(Card)``,
        Tabs: {
            Wrapper: styled(Tabs.TabWrapper)``,
            Options: styled(Tabs.TabOptions)`
                margin: 20px;
            `,
            Content: styled(Tabs.TabContent)``
        },
        Title: styled.div`
            margin: 20px 0 40px;

            > h1 {
                margin-bottom: 10px;
                font-size: 2rem;
                color: ${(props) => props.theme['text--300']};
                ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
            }

            > p {
                max-width: 760px;
                font-size: 1.4rem;
                color: ${(props) => props.theme['text--100']};
                overflow-wrap: break-word;
                ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
            }
        `,
        Content: styled.section`
            width: 100%;
            margin: 0;

            > section {
                width: 100%;
                margin: 0px !important;
                box-shadow: none;
            }
        `,
        Action: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            width: fill-available;
        `
    },
    ErrorWrapper: styled.div`
        width: 100%;
    `
}

export const Product = {
    Selection: styled(motion.div)`
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        gap: 10px;
    `
}

export const Checkout = {
    Container: styled.div`
        display: flex;
        gap: 10px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            flex-direction: column;
        }
    `,
    Base: styled.div`
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
    `,
    Payment: styled(Card)`
        padding: 20px;
        width: 100%;
    `,
    Loading: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 261px;
        width: 100%;
        min-width: 280px;
        border-radius: 16px;
        background-color: ${(props) => props.theme['white--100']};
    `,
    Content: styled(Card)`
        box-shadow: none;
        width: 100%;
        padding: 20px;
    `,
    Summary: styled(Card)`
        display: flex;
        padding: 20px;
        box-shadow: none;
    `,
    Row: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;

        &.cartItem {
            &__configurationRow {
                margin-top: 15px;
            }
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            display: flex;
            flex-flow: wrap;
        }
    `,
    Column: styled.div`
        display: inline-flex;
        flex-flow: column nowrap;
        width: 100%;

        &.cartItem {
            &__title {
                font-size: 1.4rem;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.setFont('Inter', Weight.SemiBold)};

                &.total {
                    text-align: right;
                    padding-right: 55px;
                }

                &.product {
                    min-width: 350px;
                    padding-left: 55px;
                }

                &.icon {
                    min-width: 55px;
                    width: 55px;
                }
            }

            &__product {
                min-width: 350px;
                width: fill-available;
            }

            &__remove {
                width: fit-content;
            }

            &__icon {
                width: fit-content;
            }

            &__action {
                cursor: pointer;
                display: flex;
                align-items: flex-start;
                flex-flow: row-reverse nowrap;
                width: fit-content;
                margin: 2px 0 0 10px;
            }
        }
    `,
    Title: styled.div<{ open: boolean | undefined }>`
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        text-transform: uppercase;
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        ${Theme.Functions.animation('all', 300)};

        &:hover {
            color: ${(props) => props.theme['text--300']};

            > svg {
                color: ${(props) => props.theme['text--300']};
            }
        }

        ${({ open }) => {
            if (open) {
                return css`
                    color: ${(props) => props.theme['text--300']};

                    > svg {
                        color: ${(props) => props.theme['text--300']};
                        transform: rotate(180deg);
                    }
                `
            }
        }}
    `,
    Icon: styled.div`
        display: inline-flex;
        align-items: flex-start;
        height: 100%;
        margin: 3px 10px 0 0;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            display: none;
        }
    `,
    RemoveIcon: styled(TrashIcon)`
        width: 18px;
        color: ${(props) => props.theme['error--100']};
    `,
    Actions: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: fill-available;
        padding: 20px;
        border-radius: 0 0 16px 16px;
        background-color: ${(props) => props.theme['background--100']};

        button {
            margin: 0 0 0 10px;

            &:last-of-type {
                margin: 0 0 0 20px;
            }
        }
    `,
    Submit: styled(Button)`
        width: 100%;
        margin: 0;
        justify-content: center;
        margin-bottom: 10px;
    `,
    Back: styled(Button)`
        width: 100%;
        margin: 0;
        justify-content: center;
    `
}

export const Promotion = {
    Base: styled.div`
        display: grid;
        gap: 10px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
    `,
    Form: styled(Form)`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 5px;
        width: 100%;

        > button {
            margin: 0;
        }

        > div {
            width: 100%;
            margin: 0;
        }
    `,
    Label: styled.div`
        display: inline-flex;
        text-transform: uppercase;
        font-size: 1.2rem;
        color: ${(props) => props.theme['primary--100']};
    `
}

export const CartItem = {
    List: styled.div`
        width: 100%;
    `,
    Base: styled.div`
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 8px;
        background-color: ${(props) => props.theme['background--000']};
        box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
            0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};

        &:last-of-type {
            margin-bottom: 0;
        }
    `,
    Icon: {
        Register: styled(GlobeAltIcon)`
            margin: 0 10px;
            width: 24px;
            color: ${(props) => props.theme['primary--100']};
        `,
        Transfer: styled(ArrowsUpDownIcon)`
            margin: 0 10px;
            width: 24px;
            color: ${(props) => props.theme['primary--100']};
        `,
        Product: styled(ServerIcon)`
            margin: 0 10px;
            width: 24px;
            color: ${(props) => props.theme['primary--100']};
        `
    },
    Title: styled.h2`
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            font-size: 14px;
            font-weight: bold;
        }
    `,
    Subtitle: styled.h3`
        text-transform: capitalize;
        color: ${(props) => props.theme['text--100']};
        display: flex;
        gap: 5px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            font-size: 12px;
        }
    `,
    ItemPrice: {
        Total: styled.h2`
            font-size: 1.6rem;
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};

            &.highlight {
                color: ${(props) => props.theme['primary--100']};
            }
        `,
        Discount: styled.h3`
            text-decoration: line-through;
            font-size: 1.2rem;
        `,
        BillingCycle: styled.span`
            color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], '0.5')};
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
                font-size: 14px;
            }
        `
    },
    Price: {
        Total: styled.h2`
            font-size: 1.6rem;

            &.highlight {
                color: ${(props) => props.theme['primary--100']};
            }
        `,
        Discount: styled.h3`
            text-decoration: line-through;
            font-size: 1.2rem;
        `,
        Promotion: styled.h3`
            display: inline-flex;
            text-transform: uppercase;
            font-size: 1.2rem;
            color: ${(props) => props.theme['primary--100']};
        `,
        PromotionIcon: styled(TicketIcon)`
            width: 14px;
            margin-right: 5px;
            color: ${(props) => props.theme['primary--100']};
        `
    },
    Remove: {
        Base: styled.div`
            display: flex;
            justify-content: center;
            flex-flow: column wrap;
        `,
        TrashIcon: styled(TrashIcon)`
            color: ${(props) => props.theme['error--100']};
            ${Theme.Functions.animation('all', 300)};

            &:hover {
                color: ${(props) => props.theme['error--200']};
            }
        `
    },
    Product: styled.div`
        width: 100%;
        flex: 1 1 0;
    `,
    Total: styled.div`
        width: 100%;
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        align-self: flex-end;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            align-items: flex-start;
            margin-top: 4px;
        }
    `,
    Container: styled.div`
        display: flex;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            width: 100%;
            display: inline-flex;
            justify-content: start;
            align-items: start;
        }
    `,

    Configure: {
        Base: styled.div`
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid ${(props) => props.theme['border--100']};
        `,
        Dropdown: styled.div<{ open: boolean; height: number | undefined }>`
            display: flex;
            max-height: 0;
            visible: hidden;
            gap: 20px;
            overflow: hidden;
            transition: max-height 250ms 0s, opacity 0s 200ms;

            ${({ open, height }) => {
                if (open && height) {
                    return css`
                        visibility: visible;
                        max-height: ${height < 256 ? height : 254 + 2}px;
                        transition: max-height 250ms 0s, opacity 0s 0ms;
                        overflow: ${height < 256 ? 'hidden' : 'auto'};
                    `
                }
            }}
        `,
        Icon: styled(ChevronDownIcon)`
            width: 18px;
            color: ${(props) => props.theme['icon--100']};
            ${Theme.Functions.animation('all', 300)};
        `,
        Edit: styled(PencilIcon)`
            width: 18px;
            margin: 0px 4px;
            color: ${(props) => props.theme['icon--100']};
            ${Theme.Functions.animation('all', 300)};

            &:hover {
                color: ${(props) => props.theme['icon--300']};
            }
        `,
        Label: styled.div`
            font-size: 1.2rem;
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `,
        Settings: styled.div`
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 10px;
            border-radius: 8px;
            background-color: ${(props) => props.theme['background--100']};
            box-shadow: inset 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
                inset 0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
        `,
        Setting: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 7px;
            padding: 10px;
            color: ${(props) => props.theme['text--100']};

            > svg {
                width: 14px;
                color: ${(props) => props.theme['text--100']};
            }
        `,
        Link: styled(Link)`
            display: flex;
            align-items: center;
            gap: 7px;
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            transition: color 0.5s ease-out;
            color: ${(props) => props.theme['text--100']};

            &:hover {
                color: ${(props) => props.theme['text--200']};
            }
        `,
        Config: styled.span`
            font-size: 1.2rem;
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `,
        Option: styled.span`
            color: ${(props) => props.theme['text--100']};
            font-size: 1.2rem;
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `
    }
}

export const Services = {
    Base: styled(Fade)`
        display: block;
        height: 100%;
        width: 100%;
    `,
    Footer: styled.footer`
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;
        padding: 10px 15px;
        border-radius: 0 0 16px 16px;
        background-color: ${(props) => props.theme['background--100']};
    `,
    Shop: styled(Tabs.Prefab)``,
    Label: styled.label`
        display: block;
        margin-bottom: 4px;
        font-size: 1.2rem;
        color: ${(props) => props.theme[`text--200`]};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont('Inter', 700)};

        > span {
            margin-left: 4px;
            color: ${(props) => props.theme[`error--100`]};
        }
    `,
    Content: styled.div`
        margin: 20px;
    `,
    Search: styled(Form)`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        width: 100%;
        padding: 20px;
        gap: 0 5px;
    `,
    Subtitle: styled.h2`
        margin: 0 20px 10px;
        letter-spacing: -0.5px;
        font-size: 1.8rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], '0.8')};
        ${Theme.Functions.setFont('Inter', Weight.Bold)};
    `,
    Description: styled.p`
        margin: 0 20px 10px;
        letter-spacing: -0.5px;
        font-size: 1.4rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    Filter: styled.div`
        margin: 0 20px 20px 20px;
    `,
    Results: styled.section`
        margin: 20px;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme['border--100']};
    `,
    Suggested: styled.section`
        padding-inline: 10px;
    `,
    Loading: styled(motion.div)`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;
        width: 100%;
    `,
    Check: styled.div<{ color: TColors }>`
        padding: 15px 20px;
        margin: 0 20px;
        border-radius: 8px;
        text-align: center;
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};

        ${(props) => {
            switch (props.color) {
                case 'confirm':
                    return css`
                        background-color: ${Theme.Functions.hexToRGBA(props.theme['confirm--100'], '0.15')};
                        color: ${props.theme['confirm--100']};
                    `

                case 'error':
                    return css`
                        background-color: ${Theme.Functions.hexToRGBA(props.theme['error--100'], '0.15')};
                        color: ${props.theme['error--100']};
                    `

                /*
                 * secondary--100 is invalid, fix this the next time this component is being worked on
                 * @link {src/declarations/styled.d.ts}
                 */
                default:
                    return css`
                        background-color: ${Theme.Functions.hexToRGBA(props.theme['secondary--100'], '0.15')};
                        color: ${props.theme['secondary--100']};
                    `
            }
        }}
    `,
    Tab: {
        Base: styled.div``,
        Embedded: styled.div`
            margin: 0 20px 10px;
        `,
        Description: styled.div`
            margin-bottom: 20px;
            letter-spacing: -0.5px;
            font-size: 1.4rem;
            border-bottom: 2px solid transparent;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `
    },
    Container: styled.div`
        margin: 20px;
        display: grid;
        gap: 10px;
    `,
    Addon: {
        Base: styled.article`
            border: 1px solid ${(props) => props.theme['border--100']};
            border-radius: 8px;
            padding: 15px;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        `
    },
    ConfigOptionGroup: styled.div``,
    GroupHeading: styled.h2`
        padding: 10px 0;
    `,
    ConfigOption: {
        Label: styled.label`
            display: block;
            margin-bottom: 4px;
            font-size: 1.2rem;
            color: ${(props) => props.theme[`text--200`]};
            user-select: none;
            ${Theme.Functions.normalizeText()};
            ${Theme.Functions.setFont('Inter', 700)};

            > span {
                color: ${(props) => props.theme[`primary--100`]};
                padding-left: 8px;
            }
        `
    }
}

export const Configure = {
    Base: styled.div`
        display: block;
        height: 100%;
        width: 100%;
    `,
    Title: styled.h1`
        display: flex;
        justify-content: space-between;
        color: ${(props) => props.theme['text--300']};
        font-size: 1.8rem;
        padding: 2rem;
        border-radius: 16px 16px 0 0;
        background-color: ${(props) => props.theme['background--100']};
        ${Theme.Functions.setFont('Inter', Weight.Bold)};

        &:first-child {
            border-bottom: 1px solid ${(props) => props.theme['border--100']};
        }

        > span {
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-flow: column nowrap;

            > span {
                &:last-of-child {
                    color: ${(props) => props.theme['text--100']};
                    ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
                }
            }
        }
    `,
    Content: styled.section`
        border-top: 1px solid ${(props) => props.theme['border--100']};
    `
}

export const Summary = {
    Container: styled(motion.article)`
        position: relative;
        min-width: 420px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            min-width: 280px;
        }
    `,
    Base: styled(Card)``,
    Title: styled.h2`
        width: 100%;
        margin: 0px 0px 20px;
        padding-bottom: 20px;
        letter-spacing: -0.5px;
        font-size: 1.8rem;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], '0.8')};
        ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
    `,
    Total: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};

        h2 {
            font-size: 1.8rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
        }

        p {
            font-size: 1.8rem;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
        }
    `,
    TaxTotal: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;

        h2 {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }

        p {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }
    `,
    Subtotal: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;

        h2 {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }

        p {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }
    `,
    Discount: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        h2 {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }

        p {
            font-size: 1.4rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        }
    `,
    Product: {
        Base: styled.div<{ active: boolean }>`
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            margin: 5px 20px;
            padding: 10px;
            gap: 15px;
            border-radius: 8px;
            transition: border 0.1s ease-out;
            border: ${(props) => (props.active ? `1.5px solid ${props.theme['gradient-primary--100']}` : `1px solid ${props.theme['border--100']}`)};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xs'])} {
                padding: 10px 5px;
            }
        `,
        Row: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 100%;
        `,
        Column: styled.div`
            display: inline-flex;
            flex-flow: column nowrap;
            width: 100%;
        `,
        Title: styled.h2`
            font-size: 1.6rem;
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
        `,
        Status: styled(Badge)`
            align-self: center;
        `,
        Premium: styled(Badge)`
            align-self: flex-end;
            margin: 3px 0 20px;
        `,
        Subheading: styled.h4`
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
            transition: color 0.5s ease-out;

            &:hover {
                color: ${(props) => props.theme['text--200']};
            }
        `,
        LoaderWrapper: styled.div`
            margin-left: 4px;
            margin-right: -7px;
        `,
        Meta: styled.div`
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 5px;
        `,
        Toggle: styled.div`
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
            display: flex;
            align-items: center;
            gap: 5px;
        `,
        Price: styled.p`
            letter-spacing: -0.25px;
            font-size: 1.4rem;
            text-align: right;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};
        `,
        Original: styled.h3`
            text-align: right;
            text-decoration: line-through;
            font-size: 1.2rem;
        `,
        Total: styled.h2`
            text-align: right;
            font-size: 1.6rem;
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};

            &.highlight {
                color: ${(props) => props.theme['primary--100']};
            }
        `,
        BillingCycle: styled.span`
            color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], '0.5')};
        `,
        Remove: styled(Anchor)<{ disabled: boolean }>`
            margin: 0;
            color: ${(props) => props.theme['error--100']};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['error--100'], '0.1')};

            &:hover {
                background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['error--100'], '0.2')};
            }

            ${({ disabled }) =>
                disabled &&
                css`
                    pointer-events: none;
                    opacity: 0.8;
                    user-select: none;
                `}
        `,
        Configure: styled(Anchor)`
            margin: 0;
        `,
        Actions: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            gap: 5px;
        `,
        Addons: styled.div`
            display: grid;
            gap: 10px;
        `,
        Addon: styled(motion.div)`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-break: anywhere;
        `,
        Subtotal: styled.div`
            display: flex;
            align-items: center;
            gap: 5px;
        `,
        Discount: styled.div`
            display: flex;
            flex-direction: column;
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
        `,
        Before: styled.span`
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
            text-decoration: line-through;
        `,
        After: styled.span`
            text-align: right;
            font-size: 1.2rem;
            color: ${(props) => props.theme['primary--100']};
            ${Theme.Functions.setFont('Inter', Weight.Bold)};
        `,
        Link: styled(Link)`
            letter-spacing: -0.25px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
            transition: color 0.5s ease-out;

            &:hover {
                color: ${(props) => props.theme['text--200']};
            }
        `,
        Name: styled.div`
            display: flex;
            gap: 5px;
            max-width: 250px;

            > span {
                max-width: 220px;
            }
        `,
        PlusCircleIcon: styled(PlusCircleIcon)``,
        TrashIcon: styled(TrashIcon)<{ isAnyAddonLoading: boolean }>`
            color: ${(props) => props.theme['error--100']};
            opacity: ${({ isAnyAddonLoading }) => (isAnyAddonLoading ? 0.5 : 1)};
        `
    },
    Items: styled.div``,
    Description: styled.div`
        margin-top: 10px;
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.normalizeText()};
        ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.Bold)};
    `,
    Actions: {
        Base: styled.div`
            width: 100%;
            padding: 20px;
        `,
        Submit: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            gap: 20px;
            width: 100%;
            margin-top: 20px;

            > button {
                margin: 0;
            }
        `
    },
    Cart: {
        Base: styled.div`
            display: flex;
            flex-flow: column;
            width: 100%;
        `,
        Title: styled.h2`
            padding: 20px;
            border-radius: 16px 16px 0 0;
            color: ${(props) => props.theme['text--100']};
            background-color: ${(props) => props.theme['background--100']};
            ${Theme.Functions.title('1.6rem', Weight.Bold)};
            margin-bottom: 20px;
        `,
        Item: styled.div``,
        Empty: {
            Base: styled.div`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 40px 20px 35px;
                font-size: 2rem;
                color: ${(props) => props.theme['text--200']};
                ${Theme.Functions.title()};
            `,
            Icon: styled(ShoppingCartIcon)`
                width: 48px;
                margin-bottom: 10px;
                color: ${(props) => props.theme['text--100']};
            `,
            Configure: styled(AdjustmentsHorizontalIcon)`
                width: 48px;
                margin-bottom: 10px;
                color: ${(props) => props.theme['text--100']};
            `,
            Description: styled.p`
                margin-bottom: 3rem;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.body('1.4rem', Weight.Medium)};
            `,
            Link: styled(Anchor)``
        },
        Disable: styled(Button)`
            cursor: default;
            color: ${(props) => props.theme['text--100']};
            background-color: ${(props) => props.theme['gray--100']};

            &:hover {
                color: ${(props) => props.theme['text--100']};
                background-color: ${(props) => props.theme['gray--100']};
            }
        `
    }
}

export const CheckoutOrder = {
    Base: styled.div`
        display: flex;
        gap: 15px;
        width: 100%;
    `,
    Container: styled.div`
        flex: 1;
        height: min-content;
    `,
    Content: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;
        text-align: center;
        padding: 24px;
    `,
    IconSuccess: styled.div`
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #e3fcf1;

        > svg {
            fill: #008055;
            width: 20px;
            height: 20px;
        }
    `,
    IconWarning: styled.div`
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], 0.175)};

        > svg {
            fill: ${(props) => props.theme[`warning--100`]};
            width: 20px;
            height: 20px;
        }
    `,
    Header: styled.div`
        display: grid;
        gap: 8px;

        > h1 {
            font-size: 1.8rem;
            ${Theme.Functions.setFont('Inter', Weight.Medium)};
            color: ${(props) => props.theme['text--300']};
        }

        h2 {
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: ${(props) => props.theme['text--100']};
        }
    `,
    Message: styled.p`
        color: ${(props) => props.theme['text--100']};
    `,
    Footer: styled.div`
        display: flex;
        justify-content: center;
        padding: 22px 0;
        background-color: ${(props) => props.theme['background--100']};
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    `,
    Anchor: styled(Link)`
        color: ${(props) => props.theme['primary--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    AnchorButton: styled.button`
        color: ${(props) => props.theme['primary--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    InvoiceHeader: styled.header`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        color: ${(props) => props.theme['text--100']};
        /* background-color: ${(props) => props.theme['background--000']}; */
        ${Theme.Functions.setFont('Inter', 400)};

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            padding: 1.5rem;
        }
        strong {
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.setFont('Inter', 500)};
        }
    `,
    InvoiceFooter: styled.footer`
        width: 100%;
    `
}
