import Yup from 'helpers/yup'
import { AddressSchema } from 'models/schemas/account/address'

/**********************************************************************************************************
 *   SCHEMA START
 **********************************************************************************************************/
const LastLoginSchema = Yup.object({
    time: Yup.number().required(),
    ip: Yup.string().required(),
    host: Yup.string()
})

const SecurityQuestionSchema = Yup.object({
    id: Yup.number(),
    question: Yup.string()
})

export const UserSchema = Yup.object({
    id: Yup.number().required(),
    email: Yup.string().required(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    address: AddressSchema.required(),
    phone: Yup.string().required(),
    date_of_birth: Yup.string().optional().nullable(),
    customer_since: Yup.string().required(),
    updated_at: Yup.string().required(),
    last_login: LastLoginSchema.optional().nullable(),
    security_question: SecurityQuestionSchema.optional().nullable(),
    security_answer: Yup.string().nullable().optional()
})
