/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Dialog } from '@headlessui/react'
import styled, { createGlobalStyle } from 'styled-components'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import * as Theme from '../../theme/theme'
import { Badge } from '../badge'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
export const GlobalLightboxStyle = createGlobalStyle`
      .nxLightbox {
          &__root {
              position: absolute;
              top: 0;
              width: 100vw;
              height: 100vh;
              place-content: center;
              z-index: 20;
              overflow: auto;
          }
          &__dialog {
              position: fixed;
              z-index: 20;
              top: 0;
              right: 0;
              left: 0;
              height: 100dvh;
              overflow-y: auto;
          }
          &__transition {
              &--enter {
                  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                  transition-duration: 300ms;
              }
              &--enterFrom {
                  opacity: 0;
              }
              &--enterTo {
                  opacity: 1;
              }
              &--leave {
                  transition-timing-function: cubic-bezier(0, 0, 1, 1);
                  transition-duration: 200ms;
              }
              &--leaveFrom {
                  opacity: 1;
              }
              &--leaveTo {
                  opacity: 0;
              }
          }
          &Content__transition {
              &--enter {
                  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                  transition-duration: 300ms;
              }
              &--enterFrom {
                  opacity: 0;
                  transform: translateY(16px) scale(0.95);
                  ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                      transform: translateY(0px);
                      transform: scale(0.95);
                  }
              }
              &--enterTo {
                  opacity: 1;
                  transform: translateY(0px);
                  ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                      transform: scale(1);
                  }
              }
              &--leave {
                  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
                  transition-duration: 200ms;
              }
              &--leaveFrom {
                  opacity: 1;
                  transform: translateY(0px);
                  ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                      transform: scale(1);
                  }
              }
              &--leaveTo {
                  opacity: 0;
                  transform: translateY(16px) scale(0.95);
                  ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                      transform: translateY(0px);
                      transform: scale(0.95);
                  }
              }
          }
      }
  `

export const Lightbox = {
    Wrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        padding: 8px 8px;
        text-align: center;
        ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
            display: block;
            padding: 0;
        }
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`background--200`], `0.9`)};
        }
        .spanHidden {
            visibility: hidden;
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                display: inline-block;
                vertical-align: middle;
                height: 100vh;
            }
        }
        .lightboxDialog {
            position: fixed;
            z-index: 10;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-y: auto;
        }
        .primary {
            color: ${(props) => props.theme[`primary--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`primary--100`], `0.15`)};
        }
        .confirm {
            color: ${(props) => props.theme[`confirm--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`confirm--100`], `0.15`)};
        }
        .red {
            color: ${(props) => props.theme[`error--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`error--100`], `0.15`)};
        }
        .warning {
            color: ${(props) => props.theme[`warning--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], `0.15`)};
        }
        .info {
            color: ${(props) => props.theme[`info--100`]};
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`info--100`], `0.15`)};
        }
    `,
    Ref: styled.div`
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `,
    Inner: styled(Dialog.Panel)`
        position: relative;
        display: inline-block;
        min-width: 320px;
        vertical-align: bottom;
        background-color: ${(props) => props.theme[`background--100`]};
        border-radius: 8px;
        text-align: left;
        overflow: hidden;
        box-shadow: 1.7px 1.7px 2.2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.07`)},
            4px 4px 5.3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.05`)},
            7.5px 7.5px 10px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.042`)},
            13.4px 13.4px 17.9px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.035`)},
            25.1px 25.1px 33.4px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.028`)},
            60px 60px 80px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--200`], `0.02`)};
        ${Theme.Functions.animation(`all`)};
        ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
            max-width: 50vh;
            min-width: 500px;
            margin-top: 32px;
            margin-bottom: 32px;
            vertical-align: middle;
        }
    `,
    Default: {
        Wrapper: styled.div`
            width: 100%;
            max-height: 50vh;
            min-height: 300px;
        `,
        Header: styled.header`
            padding: 16px;
            background-color: ${(props) => props.theme[`background--000`]};
            border-bottom: 1px solid ${(props) => props.theme[`border--100`]};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                padding: 24px 64px 24px 24px;
            }
            .desc {
                margin-top: 8px;
                color: ${(props) => props.theme[`text--100`]};
                font-size: 1.4rem;
                line-height: 1.5;
                ${Theme.Functions.setFont('Inter', 400)};
            }
        `,
        Section: styled.section`
            display: inline-block;
            width: 100%;
            padding: 20px 16px 16px;
            max-height: 40vh;
            text-align: left;
            vertical-align: middle;
            overflow: auto;
            background-color: ${(props) => props.theme[`background--000`]};
            ${Theme.Functions.animation(`all`)};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                max-height: 50vh;
                padding: 24px;
            }
        `
    },
    CTA: {
        Wrapper: styled.div`
            padding: 24px;
            background-color: ${(props) => props.theme[`background--000`]};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `,
        Title: styled.div`
            text-align: center;
            > h3 {
                font-size: 1.8rem;
                line-height: 24px;
                color: ${(props) => props.theme[`text--300`]};
                ${Theme.Functions.setFont('Inter', 500)};
            }
        `,
        Description: styled.div`
            margin-top: 8px;
            color: ${(props) => props.theme[`text--100`]};
            font-size: 1.4rem;
            line-height: 20px;
            ${Theme.Functions.setFont('Inter', 400)};
        `,
        Footer: styled.footer`
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            padding: 0 20px 20px;
            background-color: ${(props) => props.theme[`background--000`]};
        `
    },
    Icon: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        margin: 0 auto;
        border-radius: 9999px;
        ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
            height: 64px;
            width: 64px;
            margin: 0 auto 16px;
        }
    `,
    Footer: {
        Wrapper: styled.footer`
            background-color: ${(props) => props.theme[`background--100`]};
            padding: 12px 16px;
            display: flex;
            flex-direction: column-reverse;
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-left: 16px;
                padding-right: 16px;
            }
        `,
        Links: styled.div`
            display: flex;
            align-items: center;
            margin-right: auto;
            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                width: 100%;
                justify-content: center;
            }
        `,
        Actions: styled.div`
            display: flex;
            flex-direction: column;
            gap: 6px;
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                flex-direction: row;
            }

            > button {
                width: 100%;
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    width: auto;
                }
            }
        `
    },
    Close: styled.div`
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        height: 36px;
        width: 40px;
        margin: 0 auto;
        flex-shrink: 0;
        border-radius: 9999px;
        padding: 16px 16px 0 0;
        color: ${(props) => props.theme[`icon--100`]};
        ${Theme.Functions.outline(`none`)};
        ${Theme.Functions.animation(`all`)};
        ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
            margin: 0;
            height: 44px;
            width: 44px;
        }
        &:hover {
            color: ${(props) => props.theme[`error--100`]};
        }
    `,
    Invoice: {
        Header: styled.header`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            color: ${(props) => props.theme[`text--100`]};
            background-color: ${(props) => props.theme[`background--000`]};
            ${Theme.Functions.setFont('Inter', 400)};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                padding: 24px;
            }
            strong {
                color: ${(props) => props.theme[`text--300`]};
                ${Theme.Functions.setFont('Inter', 500)};
            }
        `,
        Status: styled(Badge)``,
        Details: styled.div`
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        `,
        Row: styled.div`
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
            min-width: 280px;
            &:last-of-type {
                border-radius: 0 0 16px 16px;
            }
        `,
        Column: {
            Base: styled.div`
                display: inline-flex;
                flex: 1 1 0;
                background-color: ${(props) => props.theme[`background--000`]};
                min-width: 280px;
                padding: 18px 24px;
                box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                    1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 1px 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                    0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;
                &:first-of-type {
                    box-shadow: 1px 0 0 0 ${(props) => props.theme[`gray--100`]}, 0 1px 0 0 ${(props) => props.theme[`gray--100`]},
                        1px 1px 0 0 ${(props) => props.theme[`gray--100`]}, 0 0 0 0 ${(props) => props.theme[`gray--100`]} inset,
                        0 1px 0 0 ${(props) => props.theme[`gray--100`]} inset;
                }
                ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                    padding: 10px 15px;
                    flex-direction: column;
                }
            `,
            Render: styled.div`
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                text-align: right;
                color: ${(props) => props.theme[`text--300`]};
                font-size: 1.4rem;
                letter-spacing: -0.25px;
                ${Theme.Functions.setFont('Inter', 500)};
            `,
            Title: styled.div`
                display: inline-flex;
                align-items: center;
                height: 100%;
                min-width: 100px;
                margin-right: 10px;
                color: ${(props) => props.theme[`text--100`]};
                font-size: 1.4rem;
                letter-spacing: -0.25px;
                ${Theme.Functions.setFont('Inter', 400)};
                ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                    margin-right: 15px;
                    min-width: 100%;
                }
            `
        },
        Breakdown: styled.div``,
        Summary: styled.div`
            padding: 20px;
            background-color: ${(props) => props.theme[`background--000`]};
            border-top: 1px solid ${(props) => props.theme[`border--100`]};
        `
    },
    Breakdown: {
        Title: styled.div`
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: ${(props) => props.theme[`background--100`]};
            border-bottom: 1px solid ${(props) => props.theme[`border--100`]};
            padding: 0 20px;
            height: 40px;
            text-align: left;
            font-size: 1.2rem;
            color: ${(props) => props.theme[`text--100`]};
            text-transform: uppercase;
            ${Theme.Functions.setFont('Inter', 600)};
        `,
        Content: styled.div`
            background-color: ${(props) => props.theme[`background--000`]};
            padding: 20px;
        `
    },
    Summary: {
        Title: styled.div`
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: ${(props) => props.theme[`background--100`]};
            border-bottom: 1px solid ${(props) => props.theme[`border--100`]};
            padding: 0 20px;
            height: 40px;
            text-align: left;
            font-size: 1.2rem;
            color: ${(props) => props.theme[`text--100`]};
            text-transform: uppercase;
            ${Theme.Functions.setFont('Inter', 600)};
        `,
        Content: styled.div`
            background-color: ${(props) => props.theme[`background--000`]};
            padding: 20px;
        `
    }
}
