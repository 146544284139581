import { Theme } from 'nxui/src'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const FrameStyles = {
    Base: styled.div`
        ${({ theme }) => css`
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            background-color: ${theme['background--200']};

            ${Theme.Functions.mediaBreakDown(theme['breakpoints-lg'])} {
                flex-direction: column;
                margin-top: 65px;
            }
        `}
    `
}
