import { Flex } from 'components/utilities/Flex'
import { Padding } from 'components/utilities/Padding'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LightboxConfirmationActionType = React.FC<{
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _LightboxConfirmationAction: LightboxConfirmationActionType = ({ children }) => {
    /***** RENDER *****/
    return (
        <Padding.Margin top={10}>
            <Flex gap={10} justify='center'>
                {children}
            </Flex>
        </Padding.Margin>
    )
}
