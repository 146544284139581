import { Text } from 'components/utilities/Text'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfirmationLightboxDescription = React.FC<{
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _LightboxConfirmationDescription: ConfirmationLightboxDescription = ({ children }) => {
    return (
        <Text size={14} align-center gray>
            {children}
        </Text>
    )
}
