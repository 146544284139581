/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { isNumeric } from '../../../helpers/functions'
import { RenderErrorIcon, Style } from '../util'
import { Input } from './input'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

const Calendar = {
    Wrapper: styled.div``,
    Field: styled.div`
        ${Style.Field}
        align-items: center;

        svg {
            width: 48px;
            margin-right: 8px;
        }

        input {
            width: 100%;
            margin-right: 8px;
            outline: none;

            &:first-of-type {
                width: 100%;
            }
        }
    `
}

/**********************************************************************************************************
 *   INPUT FIELD
 **********************************************************************************************************/
export const DateField = ({ className, embedded, required, label, disabled }) => {
    const {
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        isSubmitting,
        dirty,
        values: { dateDay, dateMonth, dateYear }
    } = useFormikContext()
    const errorsArray = Object.keys(errors)

    /*   REF
     *****************************************************/
    const dayRef = useRef()
    const monthRef = useRef()
    const yearRef = useRef()

    /*   STATE
     *****************************************************/
    const [active, setActive] = useState(false)
    const [hasFocus, setFocus] = useState(false)

    /*   FUNCTIONS
     *****************************************************/
    function validateDay(v) {
        const max = DateTime.utc(Number(dateYear), Number(dateMonth)).daysInMonth
        if (!isNumeric(v)) v = ''

        if (/^[4-9]$/.test(v)) v = `0${v}` // if any number other than 1 was entered, add a leading zero

        if (v > max) v = max // if day is greater than max days, set to max days instead

        if (/^\d{3}$/.test(v)) {
            v = v.slice(0, -1) // give us day value
        }

        dayRef.current.value = v
        setFieldValue(`dateDay`, v)

        if (v?.length === 2) {
            monthRef.current.focus()
        }
    }

    function validateMonth(v) {
        if (!isNumeric(v)) v = ''

        if (/^[2-9]$/.test(v)) v = `0${v}` // if any number other than 1 was entered, add a leading zero
        if (/^1[/-]$/.test(v)) {
            // if ONLY the number 1 was entered, followed by a forward slash, add a leading zero and focus year
            v = `01`
            yearRef.current.focus()
        }
        if (/^\d{2}[/-]$/.test(v)) {
            // If month is completed field and contains a forward slash then remove '/' and focus year
            v = v.replace('/', '')
            yearRef.current.focus()
        }
        if (/^\d{3}$/.test(v)) {
            const y = v.slice(-1) // give us the new year value
            v = v.slice(0, -1) // give us month value without added year value
            yearRef.current.focus()
        }
        if (v > 12) v = '12'

        monthRef.current.value = v
        setFieldValue(`dateMonth`, v)

        if (v.length >= 2) yearRef.current.focus()
    }

    function validateYear(v) {
        if (!isNumeric(v)) v = ''
        v = v.length > 4 ? v.slice(0, -1) : v // give us year at 4 digits only
        v = v > 999 && v < 1900 ? 1900 : v > 2999 ? 2999 : v // Clamp between 1900 and 2999

        yearRef.current.value = v // set the value of the year input to the new value
        setFieldValue(`dateYear`, v)
    }

    function updateDayValidation() {
        if (!dateYear || dateYear.length < 4) return dateDay

        const m = dateMonth?.length === 2 ? dateMonth : DateTime.now().dateMonth
        const y = dateYear?.length === 4 ? dateYear : DateTime.now().dateYear

        const max = DateTime.utc(Number(y), Number(m)).daysInMonth

        if (dateDay > max) {
            dayRef.current.value = max
            setFieldValue(`dateDay`, max)
        }
    }

    useEffect(() => {
        if (
            (document.hasFocus() && dayRef.current?.contains(document.activeElement)) ||
            monthRef.current?.contains(document.activeElement) ||
            yearRef.current?.contains(document.activeElement)
        ) {
            setFocus(true)
        }

        if ((dateDay, dateMonth, dateYear)) {
            updateDayValidation()
        }

        if (errorsArray.length >= 1 && !hasFocus) {
            if ((!errorsArray.includes('dateMonth') || !errorsArray.includes('dateYear')) && errorsArray.includes('dateDay')) {
                setActive('dateDay')
                setFieldTouched('dateDay')
            }

            if ((!errorsArray.includes('dateDay') || !errorsArray.includes('dateYear')) && errorsArray.includes('dateMonth')) {
                setActive('dateMonth')
                setFieldTouched('dateMonth')
            }

            if ((!errorsArray.includes('dateDay') || !errorsArray.includes('dateMonth')) && errorsArray.includes('dateYear')) {
                setActive('dateYear')
                setFieldTouched('dateYear')
            }

            if (errorsArray.includes('dateDay') && errorsArray.includes('dateMonth') && errorsArray.includes('dateYear') && isSubmitting && !dirty) {
                setActive('dateDay')
                setFieldTouched('dateDay')
            }
        }
    }, [dateDay, dateMonth, dateYear, errorsArray.length, hasFocus, dirty, isSubmitting])

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Input.Wrapper className={className} embedded={embedded}>
            {label && !embedded && (
                <Input.Label>
                    {label}
                    {required && <span>*</span>}
                </Input.Label>
            )}
            <Input.Column focus={hasFocus} error={errors[active]}>
                <Calendar.Wrapper>
                    <Calendar.Field>
                        <Field name={'dateDay'}>
                            {({ field }) => (
                                <input
                                    ref={dayRef}
                                    id={'dateDay'}
                                    name={'dateDay'}
                                    type={'tel'}
                                    placeholder={'DD'}
                                    disabled={isSubmitting || disabled}
                                    autoComplete={'bday-day'}
                                    value={dateDay}
                                    onChange={(e) => validateDay(e.target.value)}
                                    onFocus={() => {
                                        setActive('dateDay')
                                        setFocus(true)
                                    }}
                                    onBlur={(e) => {
                                        field.onBlur && field.onBlur(e)
                                        setFocus(false)
                                    }}
                                />
                            )}
                        </Field>
                        <Field name={'dateMonth'}>
                            {({ field }) => (
                                <input
                                    ref={monthRef}
                                    id={'dateMonth'}
                                    name={'dateMonth'}
                                    type={'tel'}
                                    placeholder={'MM'}
                                    disabled={isSubmitting || disabled}
                                    autoComplete={'bday-month'}
                                    value={dateMonth}
                                    onChange={(e) => validateMonth(e.target.value)}
                                    onFocus={() => {
                                        setActive('dateMonth')
                                        setFocus(true)
                                    }}
                                    onBlur={(e) => {
                                        field.onBlur && field.onBlur(e)
                                        setFocus(false)
                                    }}
                                />
                            )}
                        </Field>
                        <Field name={'dateYear'}>
                            {({ field }) => (
                                <input
                                    ref={yearRef}
                                    id={'dateYear'}
                                    name={'dateYear'}
                                    type={'tel'}
                                    placeholder={'YYYY'}
                                    disabled={isSubmitting || disabled}
                                    autoComplete={'bday-year'}
                                    value={dateYear}
                                    onChange={(e) => validateYear(e.target.value)}
                                    onFocus={() => {
                                        setActive('dateYear')
                                        setFocus(true)
                                    }}
                                    onBlur={(e) => {
                                        field.onBlur && field.onBlur(e)
                                        setFocus(false)
                                    }}
                                />
                            )}
                        </Field>
                    </Calendar.Field>
                </Calendar.Wrapper>
                {RenderErrorIcon(active, touched, errors)}
            </Input.Column>
        </Input.Wrapper>
    )
}
