/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import styled, { css, type DefaultTheme, type ThemeProps } from 'styled-components'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Anchor } from 'components/buttons'
import { Theme } from 'nxui/src'

const bubbleUtils = {
    breakMd(props: ThemeProps<DefaultTheme>) {
        return Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])
    }
}

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const BaseStyling = {
    Icon: css`
        position: absolute;
        top: 0;
        right: 0;
        width: 180px;
        opacity: 0.1;

        color: ${({ theme }) => theme['white--100']};
    `,

    Action: css`
        white-space: nowrap;
        position: relative;

        .icon {
            right: -10px;
            ${Theme.Functions.animation('right stroke stroke-width', 500)};
            width: 10px;
            stroke-width: 2px;
            margin-bottom: 1.2px;
            stroke: ${({ theme }) => Theme.Functions.hexToRGBA(theme['white--100'], '0.3')};

            position: absolute;
        }

        &:hover .icon {
            right: -12px;
            stroke-width: 1px;
            stroke: ${({ theme }) => theme['white--100']};
        }
    `
}

const _BubbleStyles = {
    Bubble: styled.button<{ 'full-width'?: boolean }>`
        all: unset;
        position: relative;
        min-width: 300px;
        height: fit-content;
        padding: 20px 25px;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: ${({ theme }) => theme['primary--100']};
        box-shadow: 0 1px 3px ${({ theme }) => Theme.Functions.hexToRGBA(theme['black--100'], '0.1')},
            0 1px 2px ${({ theme }) => Theme.Functions.hexToRGBA(theme['black--100'], '0.06')};

        &[data-anywhere='true'] {
            cursor: pointer;

            &:hover .Bubble__action {
                color: ${({ theme }) => theme['white--100']};

                .icon {
                    stroke-width: 1px;
                    stroke: ${({ theme }) => theme['white--100']};
                    right: -12px;
                }
            }
        }

        ${bubbleUtils.breakMd} {
            padding: 13px 15px;
            border-radius: 10px;
        }
    `,

    Title: styled.h3`
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;

        color: ${({ theme }) => theme['white--100']};

        ${bubbleUtils.breakMd} {
            font-size: 16px;
            line-height: 19px;
        }
    `,

    Description: styled.p`
        padding: 0;
        font-size: 40px;
        font-weight: 800;

        color: ${({ theme }) => theme['white--100']};

        ${bubbleUtils.breakMd} {
            font-size: 32px;
        }
    `,

    IconWrapper: styled.span`
        ${BaseStyling.Icon}
    `,

    DefaultIcon: styled(ArrowRightCircleIcon)`
        ${BaseStyling.Icon}
    `,

    ActionButton: styled(Anchor.Button)`
        ${BaseStyling.Action}
    `,

    ActionLink: styled(Anchor.Link)`
        ${BaseStyling.Action}
    `
}
/**********************************************************************************************************
 *   STYLE END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const BubbleStyles = Object.assign(_BubbleStyles, {
    Raw: BaseStyling
})
