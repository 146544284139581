/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Dialog } from '@headlessui/react'
import { useLightboxContext } from '../utilities'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LightboxHeaderStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLightboxHeader = React.FC<{
    title?: string
    children?: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _LightboxHeader: TLightboxHeader = ({ title, children }) => {
    /***** RENDER *****/
    return (
        <S.Wrapper>
            {title && <Dialog.Title>{title}</Dialog.Title>}
            {children && <S.Description>{children}</S.Description>}
            <CloseButton />
        </S.Wrapper>
    )
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function CloseButton() {
    /***** HOOKS *****/
    const { onClose } = useLightboxContext()

    /***** RENDER *****/
    return (
        <S.CloseButtonWrapper align='center' justify='center'>
            <button type='button' onClick={onClose}>
                <XMarkIcon width='20px' />
            </button>
        </S.CloseButtonWrapper>
    )
}

/***** EXPORTS *****/
export const LightboxHeader = Object.assign(_LightboxHeader, { CloseButton })
