import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { TTagTypes } from 'api/base'
import Yup from 'helpers/yup'
import { DateTime } from 'luxon'
import { AdditionalUsersSchema } from 'models/schemas/account/additional-users'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TEndpointBuilder = EndpointBuilder<BaseQueryFn, TTagTypes, 'api'>
export type AdditionalUser = ReturnType<typeof validateSync>

/**********************************************************************************************************
 *   SCHEMA DEFINITION
 **********************************************************************************************************/
const schema = Yup.object({
    data: AdditionalUsersSchema.required()
})

const validateSync = (value: unknown) => {
    return schema.validateSync(value).data.map(({ access_expiry, ...rest }) => ({
        ...rest,
        access_expiry: access_expiry ? DateTime.fromISO(access_expiry) : undefined
    }))
}

/**********************************************************************************************************
 *   ENDPOINT
 **********************************************************************************************************/
export const additionalUsers = (builder: TEndpointBuilder) =>
    builder.query<AdditionalUser, void>({
        query: () => 'client/additional-users',
        providesTags: ['Additional-Users'],
        transformResponse: validateSync
    })
