/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Table } from './table.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{ className?: string }} props
 */
export const TableLoader = ({ className }) => {
    return (
        <Table.Base role={'progressbar'} className={className}>
            <Table.Gradient />
            <Table.Block>
                <Table.Text />
            </Table.Block>
        </Table.Base>
    )
}

export const ExportedTable = Object.assign(TableLoader, {
    S: Table
})

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ExportedTable as Table }
