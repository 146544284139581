import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { TTagTypes } from 'api/base'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TEndpointBuilder = EndpointBuilder<BaseQueryFn, TTagTypes, 'api'>

/**********************************************************************************************************
 *   ENDPOINT
 **********************************************************************************************************/
export const deletePendingAdditionalUser = (builder: TEndpointBuilder) =>
    builder.mutation<unknown, number>({
        query: (id) => ({
            url: `client/additional-users/pending/${id}`,
            method: 'DELETE'
        }),
        invalidatesTags: ['Pending-Additional-Users']
    })
