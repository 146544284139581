import Yup from 'helpers/yup'
import { RoleSchema } from 'models/schemas/account/role'
import { UserSchema } from 'models/schemas/account/user'

/**********************************************************************************************************
 *   SCHEMA START
 **********************************************************************************************************/
export const AdditionalUserAccountSchema = Yup.object({
    account_holder: Yup.string().required(),
    account_number: Yup.string().required(),
    business_number: Yup.string().nullable(),
    company_name: Yup.string(),
    id: Yup.number().required(),
    type: Yup.string().oneOf(['personal', 'business']).required()
})

export const AdditionalUserSchema = Yup.object({
    id: Yup.number().required(),
    is_primary: Yup.boolean().required(),
    access_expiry: Yup.string().nullable(),
    role: RoleSchema.required(),
    user: UserSchema.required(),
    account: AdditionalUserAccountSchema.required()
})

export const AdditionalUsersSchema = Yup.array().of(AdditionalUserSchema)
