import { CheckBox } from 'components/checkbox'
import { Anchor, Theme } from 'nxui/src'
import styled from 'styled-components'

const _CheckBox = styled(CheckBox)`
    border-radius: 0;
    padding: 0;
    width: 60%;

    &,
    &:hover {
        background: unset;
    }

    ${CheckBox.Style.ChildrenWrapper} {
        font-weight: bold;
        text-align: right;
    }
`

const LightboxBodyWrapper = styled.div`
    & p > code {
        padding: 0.2rem 0.5rem;
        margin: 0 0.2rem;
        font-size: 90%;
        white-space: nowrap;
        background: #f1f1f1;
        border: 1px solid #e1e1e1;
        border-radius: 4px;
    }

    & pre > code {
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
`

const _Anchor = styled(Anchor)`
    display: inline;
`

const UnorderedList = styled.ul`
    padding-left: 17px;

    li {
        list-style: disc;
    }
`

const OrderedListItem = styled.ol`
    padding-left: 10px;

    li {
        list-style: decimal;
        padding-left: 0;
    }
`

const BlockQuote = styled.blockquote`
    border-left: 5px solid #ccc;
    padding: 0.5em 10px;
`

const DomainContactContainer = styled.div`
    margin-top: 20px;
    padding: 20px;
    width: calc(100% + 40px);
    background-color: ${(props) => props.theme['background--100']};
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: -20px;
    justify-content: space-between;
    padding-inline: 35px;

    & > span {
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--200']};
    }
`

const CheckboxFieldWrapper = styled.div`
    div > div > label {
        background-color: transparent;
        border: 1px solid ${(props) => props.theme['background--300']};
        padding-block: 12px;
        flex-direction: row-reverse;

        & > input {
            margin-right: 10px;
        }
    }
`

const TOS = styled.div`
    margin-bottom: 8px;
    margin-top: 8px;
    color: ${(props) => props.theme['text--100']};
    text-decoration: none;
    ${Theme.Functions.setFont('Inter', 700, '1.2rem')}
    ${Theme.Functions.caption(undefined, 700)}
`

export const S = {
    CheckBox: _CheckBox,
    Anchor: _Anchor,
    TOS,
    UnorderedList,
    BlockQuote,
    OrderedListItem,
    DomainContactContainer,
    CheckboxFieldWrapper,
    LightboxBodyWrapper
}
