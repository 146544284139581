/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button, Form as NXUIForm, Table } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useAddDNSRecordsMutation } from 'api/domain'
import { useServicesQuery } from 'api/service'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { DNSRecordSchema } from 'helpers/utils'
import { removeFloatingDotFromString } from '../editRecord/_editRecord.helpers'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Records } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { FormRecord, RecordType } from 'models/domain'
import { DNSRecordType, ServiceStatus } from 'models/enums'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleAddingRecord } from 'store/slices/domainSlice'
import PreviewDNSRecordResult, { handleContentLabel } from './previewRecord'

/**********************************************************************************************************
 * TYPES
 **********************************************************************************************************/
interface Props {
    meta: ServiceMetaPayload
}

type FormValues = Omit<FormRecord, 'id'>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AddRecordForm({ meta = { page: '1', sort_by: null } }: Props) {
    /***** HOOKS *****/
    const { id } = useParams()
    const domainId = Number(id)
    const { addingRecord } = useAppSelector((state) => state.domain)
    const dispatch = useAppDispatch()

    /***** QUERIES *****/
    const [addDnsRecords, { isLoading }] = useAddDNSRecordsMutation({ fixedCacheKey: 'add-dns-record' })
    const { records } = domainAPI.endpoints.dnsRecords.useQueryState(domainId, {
        selectFromResult: ({ data }) => ({
            records:
                data?.records.map((record) => {
                    return {
                        ...record,
                        hostname: removeFloatingDotFromString(record.hostname),
                        content: removeFloatingDotFromString(record.content)
                    }
                }) ?? []
        })
    })
    const { domain } = domainAPI.endpoints.domainList.useQueryState(meta, {
        selectFromResult: ({ data }) => ({
            domain: data?.data.find((domainItem) => domainItem.id === domainId)
        })
    })
    const { resources } = useServicesQuery(
        { page: '1', sort_by: null, filter_by: [`status,${ServiceStatus.Active}`] },
        {
            selectFromResult: ({ data, ...rest }) => ({
                ...rest,
                resources: data?.data
                    .flatMap(({ name, status, product: { service_type }, server }) => {
                        return service_type !== 'shared_hosting' && status !== 'active' && server === null
                            ? []
                            : { name, ipAddress: server?.ip_address }
                    })
                    .filter(({ ipAddress }) => ipAddress !== '')
            })
        }
    )

    /***** RENDER HELPERS *****/
    const initialValues: FormValues = {
        type: 'A',
        hostname: '',
        content: '',
        priority: '5',
        ttl: '14400',
        weight: '',
        port: '',
        destination: ''
    }
    const columns = (type: RecordType) => {
        const sharedColumns = [
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: 'Hostname',
                accessor: 'hostname'
            }
        ]

        if (type === 'MX') {
            return [
                ...sharedColumns,
                {
                    Header: 'Content',
                    accessor: 'content'
                },
                {
                    Header: 'TTL',
                    accessor: 'ttl'
                },
                {
                    Header: 'Priority',
                    accessor: 'priority'
                }
            ]
        }

        if (type === 'SRV') {
            return [
                ...sharedColumns,
                {
                    Header: 'Weight',
                    accessor: 'weight'
                },
                {
                    Header: 'Port',
                    accessor: 'port'
                },
                {
                    Header: 'Destination',
                    accessor: 'destination'
                }
            ]
        }

        return [
            ...sharedColumns,
            {
                Header: 'Content',
                accessor: 'content'
            },
            {
                Header: 'TTL',
                accessor: 'ttl'
            }
        ]
    }
    const rows = (type: RecordType) => {
        const recordRows = [
            {
                type: (
                    <Field
                        label={'Type'}
                        name={'type'}
                        type={'select'}
                        list={Object.keys(DNSRecordType).flatMap((key) => {
                            return key === 'SOA' ? [] : { label: key, value: key }
                        })}
                        component={NXUIForm.SelectField}
                    />
                ),
                hostname: (
                    <Field
                        id={'hostname'}
                        label={'Hostname'}
                        placeholder={!['CNAME', 'NS'].includes(type) ? 'Use @ for root domain' : ''}
                        name={'hostname'}
                        type={'text'}
                        component={NXUIForm.InputField}
                    />
                ),
                content: (
                    <Field
                        id={'content'}
                        label={handleContentLabel(type)}
                        name={'content'}
                        placeholder={type === 'A' ? 'Select resource or enter IP Address' : ''}
                        options={Array.from(new Set(resources?.map(({ ipAddress }) => ipAddress)))}
                        disabled={isLoading}
                        nullable={true}
                        customValue={true}
                        component={type === 'TXT' ? NXUIForm.TextAreaField : NXUIForm.InputField}
                    />
                ),
                weight: <Field label={'Weight'} name={'weight'} type={'text'} component={NXUIForm.InputField} />,
                port: <Field label={'Port'} name={'port'} type={'text'} component={NXUIForm.InputField} />,
                destination: <Field label={'Destination'} name={'destination'} type={'text'} component={NXUIForm.InputField} />,
                priority: (
                    <Records.Small>
                        <Field label={'Priority'} name={'priority'} type={'text'} inputMode={'numeric'} component={NXUIForm.InputField} />
                    </Records.Small>
                ),
                ttl: (
                    <Records.Small>
                        <Field id={'ttl'} label={'TTL'} name={'ttl'} type={'text'} inputMode={'numeric'} component={NXUIForm.InputField} />
                    </Records.Small>
                ),
                action: <></>
            }
        ] as Array<object>

        if (type === 'SRV') {
            recordRows.push({
                type: (
                    <Records.Small>
                        <Field label={'Priority'} name={'priority'} type={'text'} component={NXUIForm.InputField} />
                    </Records.Small>
                ),
                hostname: (
                    <Records.Small>
                        <Field id={'ttl'} label={'TTL'} name={'ttl'} type={'text'} component={NXUIForm.InputField} />
                    </Records.Small>
                )
            })
        }

        return recordRows
    }

    /***** RENDER *****/
    if (!addingRecord) return null

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={DNSRecordSchema}
            onSubmit={async ({ hostname, content, type, destination, weight, port, ttl, priority }, { setSubmitting, resetForm }) => {
                if (domain?.domain) {
                    const newRecord = () => {
                        if (type === 'SRV') {
                            return {
                                type,
                                ttl: Number(ttl),
                                priority: Number(priority),
                                content: `${weight} ${port} ${destination}`,
                                hostname: hostname === '@' || hostname === '' ? domain?.domain : `${hostname}.${domain?.domain}`
                            }
                        }

                        if (type === 'MX') {
                            return {
                                type,
                                content,
                                ttl: Number(ttl),
                                priority: Number(priority),
                                hostname: hostname === '@' || hostname === '' ? domain?.domain : `${hostname}.${domain?.domain}`
                            }
                        }

                        return {
                            type,
                            content,
                            ttl: Number(ttl),
                            hostname: (hostname === '@' || hostname === '') && type !== 'CNAME' ? domain?.domain : `${hostname}.${domain?.domain}`
                        }
                    }

                    try {
                        const result = await addDnsRecords({ id: domainId, records: [...records, newRecord()] }).unwrap()

                        if (result) resetForm()
                        dispatch(toggleAddingRecord())
                    } catch (error) {
                        setSubmitting(false)
                    }
                }
            }}
        >
            {({ values, isSubmitting, errors }) => (
                <Records.Add id='addRecordForm'>
                    <PreviewDNSRecordResult domain={domain?.domain} {...values} />
                    <Table
                        conditions={{
                            sort: false,
                            header: false
                        }}
                        render={{
                            columns: () => columns(values.type),
                            data: () => rows(values.type)
                        }}
                    />
                    <Records.Confirm>
                        <Button type={'button'} onClick={() => dispatch(toggleAddingRecord())} color={'secondary'}>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            form='addRecordForm'
                            color={isSubmitting ? 'secondary' : 'primary'}
                            loading={!!isSubmitting}
                            disabled={Object.keys(errors).length >= 1}
                        >
                            Add
                        </Button>
                    </Records.Confirm>
                </Records.Add>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
