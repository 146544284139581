import { Theme } from 'nxui/src'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const StyledIcon = styled.div<{
    $primary?: Utilities.Falsy | true
    $confirm?: Utilities.Falsy | true
    $error?: Utilities.Falsy | true
    $warning?: Utilities.Falsy | true
    $info?: Utilities.Falsy | true
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    margin: 0 auto;
    border-radius: 100%;
    padding: 0 4px 0 4px;

    ${({ $primary, $confirm, $error, $warning, $info, theme }) => css`
        ${Theme.Functions.mediaBreakUp(theme['breakpoints-sm'])} {
            height: 64px;
            width: 64px;
            padding: 0 8px 0 8px;
        }

        color: ${() => {
            switch (true) {
                case $primary:
                    return theme['primary--100']
                case $error:
                    return theme['error--100']
                case $warning:
                    return theme['warning--100']
                case $info:
                    return theme['info--100']
                case $confirm:
                default:
                    return theme['confirm--100']
            }
        }};

        background-color: ${() => {
            switch (true) {
                case $primary:
                    return Theme.Functions.hexToRGBA(theme['primary--100'], '0.15')
                case $error:
                    return Theme.Functions.hexToRGBA(theme['error--100'], '0.15')
                case $warning:
                    return Theme.Functions.hexToRGBA(theme['warning--100'], '0.15')
                case $info:
                    return Theme.Functions.hexToRGBA(theme['info--100'], '0.15')
                case $confirm:
                default:
                    return Theme.Functions.hexToRGBA(theme['confirm--100'], '0.15')
            }
        }};
    `}
`
