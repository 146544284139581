/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import lottie from 'lottie-web'
import { useEffect, useRef, useState, type ReactNode } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import animationData from 'assets/animations/mrcLoader.json'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader/loader.styles'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
interface LottieProps {
    message?: ReactNode
    width: number
    height: number
}

interface DelayedLoaderProps {
    delay: number
    children: JSX.Element
}

export const LoaderComponent = ({ message, width, height }: LottieProps) => {
    const element = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (element.current) {
            lottie.loadAnimation({
                animationData,
                container: element.current
            })
        }
    }, [animationData])

    return (
        <Loader.Container>
            <div style={{ width, height }} ref={element} />
            <Loader.Message>{message}</Loader.Message>
        </Loader.Container>
    )
}

export const DelayedLoader = ({ delay, children }: DelayedLoaderProps) => {
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setTimeout(() => setReady(true), delay)
    }, [])

    return ready ? children : null
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { LoaderComponent as Loader }
