import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { TTagTypes } from 'api/base'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TEndpointBuilder = EndpointBuilder<BaseQueryFn, TTagTypes, 'api'>

/**********************************************************************************************************
 *   ENDPOINT START
 **********************************************************************************************************/
export const loginServiceEndpoint = (builder: TEndpointBuilder) =>
    builder.mutation<ExpectedAny, number>({
        query: (serviceId: number) => ({
            url: `client/services/${serviceId}/login`,
            method: 'GET'
        }),
        async onQueryStarted(_, { queryFulfilled }) {
            const { data, error } = await queryFulfilled.then(({ data }) => data).catch((error) => ({ error }))

            if (!error && data?.url) {
                window.open(data.url)
            }
        }
    })
