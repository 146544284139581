/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { AtSymbolIcon, InboxArrowDownIcon } from '@heroicons/react/20/solid'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Form, Formik, FormikProps } from 'formik'
import { useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import images from 'react-payment-inputs/images'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Badge, Button, Lightbox as LightboxComponent, Loader, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import CreditCardForm from 'components/invoice/credit.card.form'
import LinkTypeForm from 'components/invoice/linkTypeForm'
import { TokenTypeForm } from 'components/invoice/tokenTypeForm'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI, useAccountCreditQuery, useInvoiceQuery, usePayInvoiceMutation, useSendInvoiceEmailMutation } from 'api/billing'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppActiveInvoice } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { AccountCreditDebounce, useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import {
    defaultCurrency,
    downloadFile,
    getCreditCardImage,
    getDefaultCurrencySymbol,
    getInvoiceStatusColour,
    noop,
    parseDateToReadable,
    parseISO,
    uid
} from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { usePaymentMethods } from './hooks/usePaymentMethods'

/**********************************************************************************************************
 *   STYLE
 **********************************************************************************************************/
import { Breakdown, Description, Invoice, Line, Method, Payment, Summary } from 'components/invoice/invoice.styles'
import { AccountCredit } from 'containers/billing/billing.styles'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { AddPaymentMethod } from 'models/billing'
import { InvoiceStatus } from 'models/enums'
import { AccountCreditState } from 'store/slices/shopSlice'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function renderGenericPaymentMethodDescription(methodName: string, description: string) {
    return (
        <>
            <Payment.Divider>
                <Payment.Line />
                <Payment.Text>Pay with {methodName}</Payment.Text>
            </Payment.Divider>
            <Description.Base>
                <Description.Instructions>
                    <Markdown>{description}</Markdown>
                </Description.Instructions>
            </Description.Base>
        </>
    )
}

export default function InvoiceComponent() {
    const dispatch = useAppDispatch()
    const {
        appActiveInvoice,
        appSession: { current_account }
    } = useAppSelector((state) => state.app)

    /*   REFS
     **********************************************************************************************************/
    const creditCardRef = useRef<FormikProps<AddPaymentMethod>>(null)

    /*   STATE
     **********************************************************************************************************/
    const [template, setTemplate] = useState<'success' | 'invoice' | 'redirect'>('invoice')
    const [accountCreditToApply, setAccountCreditToApply] = useState<AccountCreditState>({ amount: 0, isValid: true })
    const [downloadingInvoice, setDownloadingInvoice] = useState(false)

    /*   QUERIES & MUTATIONS
     **********************************************************************************************************/
    const { data: invoice, isFetching: isInvoiceFetching, isSuccess: isInvoiceSuccess } = useInvoiceQuery(appActiveInvoice?.invoice?.id ?? skipToken)

    const { accountCredit } = useAccountCreditQuery(undefined, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            accountCredit: data?.account_credit
        })
    })
    const { applyAccountCreditToInvoice, isApplyAccountCreditToInvoiceLoading } = useApplyAccountCreditToInvoice()
    const [sendInvoiceEmail, { isLoading: isSendingInvoiceEmail }] = useSendInvoiceEmailMutation()
    const [payInvoice, { isLoading: isPayInvoiceLoading, isSuccess: isPayInvoiceSuccess, isError: isPayInvoiceError }] = usePayInvoiceMutation({
        fixedCacheKey: 'pay-invoice'
    })

    // Get the full list of available payment methods including default / saved method
    const {
        paymentMethods,
        selectedPaymentMethod,
        setSelectedPaymentMethodId,
        savedPaymentMethods,
        selectedSavedPaymentMethod,
        setSelectedSavedPaymentMethodId,
        isFetching: isPaymentMethodsFetching
    } = usePaymentMethods()

    /*   EFFECTS
     **********************************************************************************************************/
    useEffect(() => {
        if (isInvoiceSuccess && invoice && !isInvoiceFetching && template !== 'redirect' && template !== 'success') {
            setTemplate('invoice')
        }

        if (isPayInvoiceError) {
            creditCardRef.current?.setSubmitting(false)
        }
    }, [appActiveInvoice.status, isPayInvoiceError, isInvoiceSuccess, invoice, template, selectedPaymentMethod])

    useEffect(() => {
        if (isPayInvoiceSuccess && appActiveInvoice.status && template !== 'redirect') {
            setTemplate('success')
        }
    }, [isPayInvoiceSuccess])

    const isPayingWithOnlyAccountCredit = !!(accountCreditToApply.amount >= Number(invoice?.amount_due || 0))
    const isAccountCreditGreaterThanTotalDue = Number(accountCredit) > Number(invoice?.amount_due || 0)

    /*   JSX FUNCTIONS
     **********************************************************************************************************/
    function checkPaymentRequired() {
        if (!invoice || invoice.status !== InvoiceStatus.Unpaid || !current_account) return ''

        // Hide the pay button if the selected method is EFT or BPAY AND the invoice is not being payed with only account credit. In this case we just show the instructions
        if (selectedPaymentMethod?.formName === 'eftForm' && !isPayingWithOnlyAccountCredit) return ''

        // Paying with account credit only
        if (isPayingWithOnlyAccountCredit) {
            return (
                <Button
                    type='button'
                    color='primary'
                    onClick={async () => {
                        if (!appActiveInvoice?.invoice?.id) return
                        const { isSuccess } = await applyAccountCreditToInvoice(appActiveInvoice.invoice.id, accountCreditToApply.amount)

                        dispatch(billingAPI.endpoints.invoice.initiate(appActiveInvoice.invoice.id, { forceRefetch: true })).unwrap()

                        if (isSuccess) setTemplate('success')
                    }}
                    disabled={isApplyAccountCreditToInvoiceLoading || !accountCreditToApply.isValid}
                    loading={isApplyAccountCreditToInvoiceLoading && 'Paying Invoice...'}
                >
                    Pay Invoice
                </Button>
            )
        }

        // Paying via link type or new credit card
        if (['linkTypeForm', 'creditCardForm'].includes(selectedPaymentMethod?.formName || '')) {
            return (
                <Button
                    type='submit'
                    form={selectedPaymentMethod?.formName}
                    color='primary'
                    disabled={isPayInvoiceLoading || isApplyAccountCreditToInvoiceLoading || !accountCreditToApply.isValid}
                    loading={(isPayInvoiceLoading || isApplyAccountCreditToInvoiceLoading) && 'Paying Invoice...'}
                >
                    Pay Invoice
                </Button>
            )
        }

        // Paying via token type. Backend will return html and js which we will load into an iframe, which will generate the payment providers hosted checkout
        if (selectedPaymentMethod?.formName === 'tokenTypeForm') {
            return (
                <Button
                    type='submit'
                    form={selectedPaymentMethod.formName}
                    color='primary'
                    disabled={isApplyAccountCreditToInvoiceLoading || !accountCreditToApply.isValid}
                    loading={isApplyAccountCreditToInvoiceLoading && 'Paying Invoice...'}
                >
                    Pay Invoice
                </Button>
            )
        }

        // Paying via saved card
        if (selectedSavedPaymentMethod) {
            return (
                <Button
                    type='button'
                    color='primary'
                    onClick={async () => {
                        if (!appActiveInvoice?.invoice?.id) return

                        const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(
                            appActiveInvoice.invoice.id,
                            accountCreditToApply.amount
                        )

                        if (isSuccess && isInvoiceStillUnpaid) {
                            await payInvoice({
                                id: String(appActiveInvoice.invoice.id),
                                payment_method_id: Number(selectedSavedPaymentMethod?.payment_method.id),
                                payment_method_data_id: Number(selectedSavedPaymentMethod?.id)
                            })
                        }

                        dispatch(billingAPI.endpoints.invoice.initiate(appActiveInvoice.invoice.id, { forceRefetch: true })).unwrap()
                    }}
                    disabled={isPayInvoiceLoading || isApplyAccountCreditToInvoiceLoading || !accountCreditToApply.isValid}
                    loading={(isPayInvoiceLoading || isApplyAccountCreditToInvoiceLoading) && 'Paying Invoice...'}
                >
                    Pay Invoice
                </Button>
            )
        }

        return ''
    }

    function renderInvoiceStatus() {
        if (!invoice) {
            return null
        }

        return <Invoice.Status color={getInvoiceStatusColour(invoice.status)}>{invoice.status}</Invoice.Status>
    }

    function renderTotalSummary() {
        if (!invoice) return null

        const { status, subtotal, total_discount, total_paid, credit_used, tax, total, amount_due } = invoice

        return (
            <Summary.Base>
                <Summary.Details>
                    {subtotal && (
                        <Line.Row>
                            <Line.Title>Subtotal</Line.Title>
                            <Line.Price>{defaultCurrency(subtotal)}</Line.Price>
                        </Line.Row>
                    )}
                    {total_discount && total_discount !== '0.00' && (
                        <Line.Row>
                            <Line.Title>Discount</Line.Title>
                            <Line.Price>{defaultCurrency(total_discount)}</Line.Price>
                        </Line.Row>
                    )}
                    {credit_used && credit_used !== '0.00' && (
                        <Line.Row>
                            <Line.Title>Credit Applied</Line.Title>
                            <Line.Price>{defaultCurrency(credit_used)}</Line.Price>
                        </Line.Row>
                    )}
                    {tax && tax !== '0.00' && (
                        <Line.Row>
                            <Line.Title>GST</Line.Title>
                            <Line.Price>{defaultCurrency(tax)}</Line.Price>
                        </Line.Row>
                    )}
                    {total && (
                        <Line.Row>
                            <Line.Title>Total</Line.Title>
                            <Line.Price>{defaultCurrency(total)}</Line.Price>
                        </Line.Row>
                    )}
                    <Summary.Total.Base>
                        <Summary.Total.Title>
                            {status === 'paid' && total_paid && total_paid !== '0.00' ? 'Total Amount Paid' : 'Total Amount Due'}
                        </Summary.Total.Title>
                        <Summary.Total.Price>{defaultCurrency(status === 'paid' ? total_paid : amount_due)}</Summary.Total.Price>
                    </Summary.Total.Base>
                </Summary.Details>
            </Summary.Base>
        )
    }

    const isLightboxLoading = (isInvoiceFetching || isPaymentMethodsFetching) && template !== 'redirect' && template !== 'success'

    if (isLightboxLoading) {
        return (
            <LightboxComponent
                id={template}
                type='confirm'
                icon='info'
                title='Fetching Invoice'
                loading='Loading Invoice Summary...'
                close={() => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))}
                conditions={{
                    state: appActiveInvoice.status,
                    action: () => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))
                }}
            />
        )
    }

    let conditionalProps

    switch (template) {
        case 'redirect':
            conditionalProps = {
                id: template,
                type: 'confirm',
                icon: 'info',
                title: `Redirecting to ${selectedPaymentMethod?.name}`,
                description: `Please wait while we redirect you to ${selectedPaymentMethod?.name}`,
                close: () => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false })),
                conditions: {
                    state: appActiveInvoice.status,
                    action: () => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))
                }
            }
            break

        case 'success':
            conditionalProps = {
                id: template,
                type: 'success',
                icon: 'success',
                title: 'Invoice Paid',
                description: 'You can now see this reflected on the Billing page.',
                links: [
                    {
                        label: 'Back to Billing',
                        color: 'primary',
                        target: '_blank',
                        href: 'https://nexigen.digital/contact'
                    }
                ],
                conditions: {
                    state: appActiveInvoice.status,
                    action: () => {
                        dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))
                        setTemplate('invoice')
                    }
                }
            }
            break

        case 'invoice':
        default:
            conditionalProps = {
                id: template,
                type: 'custom',
                close: () => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false })),
                content: (
                    <>
                        <Invoice.Header>
                            <h2>
                                Invoice <strong>{invoice?.id}</strong>
                            </h2>
                            {renderInvoiceStatus()}
                        </Invoice.Header>
                        <Invoice.Details>
                            <Invoice.Row key={uid()}>
                                <Invoice.Column.Base key={uid()}>
                                    <Invoice.Column.Title>Account #</Invoice.Column.Title>
                                    <Invoice.Column.Render>
                                        {current_account?.account && <Badge>{current_account.account.account_number}</Badge>}
                                    </Invoice.Column.Render>
                                </Invoice.Column.Base>
                            </Invoice.Row>
                            <Invoice.Row key={uid()}>
                                <Invoice.Column.Base key={uid()}>
                                    <Invoice.Column.Title>Due Date</Invoice.Column.Title>
                                    <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice.due_date) : ''}</Invoice.Column.Render>
                                </Invoice.Column.Base>
                                <Invoice.Column.Base key={uid()}>
                                    <Invoice.Column.Title>Date Issued</Invoice.Column.Title>
                                    <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice?.created_at) : ''}</Invoice.Column.Render>
                                </Invoice.Column.Base>
                            </Invoice.Row>
                            {invoice?.status === 'paid' ? (
                                <Invoice.Row key={uid()}>
                                    <Invoice.Column.Base key={uid()}>
                                        <Invoice.Column.Title>Paid Date</Invoice.Column.Title>
                                        <Invoice.Column.Render>{invoice ? parseDateToReadable(invoice.date_paid) : ''}</Invoice.Column.Render>
                                    </Invoice.Column.Base>
                                </Invoice.Row>
                            ) : (
                                <Invoice.Row key={uid()}>
                                    <Invoice.Column.Base key={uid()}>
                                        <Invoice.Column.Title>Amount Paid</Invoice.Column.Title>
                                        <Invoice.Column.Render>{defaultCurrency(invoice?.total_paid)}</Invoice.Column.Render>
                                    </Invoice.Column.Base>
                                </Invoice.Row>
                            )}
                        </Invoice.Details>
                        <Invoice.Breakdown>
                            <Breakdown.Title>Invoice Breakdown</Breakdown.Title>
                            <Breakdown.Content>
                                {invoice && invoice.invoice_items
                                    ? invoice.invoice_items.map(({ id, description, total }) => (
                                          <Line.Row key={id}>
                                              <Line.Title>{description}</Line.Title>
                                              <Line.Price>{defaultCurrency(total)}</Line.Price>
                                          </Line.Row>
                                      ))
                                    : ''}
                            </Breakdown.Content>
                        </Invoice.Breakdown>
                        <Invoice.Footer>
                            {invoice?.status === 'unpaid' && (
                                <Payment.Base>
                                    <Payment.Header>
                                        <Payment.Content>
                                            <Payment.Title>Pay this invoice</Payment.Title>
                                            <Payment.Description>
                                                Make a payment for this invoice by filling out the details below.
                                            </Payment.Description>
                                        </Payment.Content>
                                    </Payment.Header>
                                    <Payment.Body>
                                        {accountCredit && accountCredit !== '0.00' ? (
                                            <Formik
                                                initialValues={{
                                                    accountCreditAmount: '0.00',
                                                    applyAccountCredit: false
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    accountCreditAmount: Yup.number()
                                                        .min(0, 'Please enter a positive number.')
                                                        .max(
                                                            isAccountCreditGreaterThanTotalDue
                                                                ? Number(invoice?.amount_due || 0)
                                                                : Number(accountCredit),
                                                            isAccountCreditGreaterThanTotalDue
                                                                ? 'Amount exceeds total due today.'
                                                                : 'Amount exceeds current account credit balance.'
                                                        )
                                                })}
                                                onSubmit={noop}
                                            >
                                                {({ setFieldValue, values }) => {
                                                    return (
                                                        <Form>
                                                            <AccountCredit.Container>
                                                                <NXUIForm.CurrencyField
                                                                    label={`Apply Account Credit (${defaultCurrency(accountCredit)})`}
                                                                    disabled={!values.applyAccountCredit}
                                                                    name={'accountCreditAmount'}
                                                                    prefix={getDefaultCurrencySymbol()}
                                                                />
                                                                <NXUIForm.Switch
                                                                    checked={values.applyAccountCredit}
                                                                    onClick={() => {
                                                                        function getNewCreditFieldValue() {
                                                                            if (values.applyAccountCredit) return '0.00'
                                                                            if (Number(accountCredit) < Number(invoice?.amount_due)) {
                                                                                return accountCredit
                                                                            }
                                                                            return invoice?.amount_due
                                                                        }

                                                                        setFieldValue('accountCreditAmount', getNewCreditFieldValue())
                                                                        setFieldValue('applyAccountCredit', !values.applyAccountCredit)
                                                                    }}
                                                                />
                                                            </AccountCredit.Container>
                                                            <AccountCreditDebounce
                                                                onStopTyping={(values, isValid) => {
                                                                    setAccountCreditToApply({ amount: Number(values.accountCreditAmount), isValid })
                                                                }}
                                                            />
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        ) : (
                                            ''
                                        )}

                                        <Method.Wrapper aria-label={'Tabs'}>
                                            {savedPaymentMethods.length > 0 ? (
                                                <Method.Option.Wrapper
                                                    type='button'
                                                    className={`${selectedSavedPaymentMethod ? 'active' : ''}${
                                                        isPayingWithOnlyAccountCredit ? ' disabled' : ''
                                                    }`}
                                                    aria-current={selectedPaymentMethod?.name === name ? 'page' : undefined}
                                                    onClick={() => {
                                                        if (isPayingWithOnlyAccountCredit) return
                                                        setSelectedSavedPaymentMethodId()
                                                    }}
                                                >
                                                    <Method.Option.Label>Saved Payment Methods</Method.Option.Label>
                                                </Method.Option.Wrapper>
                                            ) : (
                                                ''
                                            )}

                                            {selectedSavedPaymentMethod && !isPayingWithOnlyAccountCredit
                                                ? savedPaymentMethods.map(({ id, is_default, identifier, type, expiry }) => {
                                                      return (
                                                          <Method.NestedContent key={id}>
                                                              <Method.Option.Wrapper
                                                                  type='button'
                                                                  className={`${selectedSavedPaymentMethod?.id === id ? 'active' : ''}${
                                                                      isPayingWithOnlyAccountCredit ? ' disabled' : ''
                                                                  }`}
                                                                  aria-current={selectedPaymentMethod?.name === name ? 'page' : undefined}
                                                                  onClick={() => {
                                                                      if (isPayingWithOnlyAccountCredit) return
                                                                      setSelectedSavedPaymentMethodId(id)
                                                                  }}
                                                              >
                                                                  {is_default ? (
                                                                      <Method.Saved.BadgeBackground>
                                                                          <Method.Saved.Badge color='primary'>default</Method.Saved.Badge>
                                                                      </Method.Saved.BadgeBackground>
                                                                  ) : (
                                                                      ''
                                                                  )}
                                                                  <Method.Saved.Type>
                                                                      <svg {...images[getCreditCardImage(type)].props} />
                                                                  </Method.Saved.Type>
                                                                  <Method.Saved.Card>**** **** **** {identifier}</Method.Saved.Card>
                                                                  {expiry ? (
                                                                      <Method.Saved.Expiry>
                                                                          {`${parseISO(expiry).month}/${parseISO(expiry).year}`}
                                                                      </Method.Saved.Expiry>
                                                                  ) : (
                                                                      ''
                                                                  )}
                                                                  {isPayingWithOnlyAccountCredit ? (
                                                                      ''
                                                                  ) : (
                                                                      <Method.Option.Icon.Fade when={selectedSavedPaymentMethod}>
                                                                          {selectedSavedPaymentMethod?.id === id ? (
                                                                              <Method.Option.Icon.Wrapper>
                                                                                  <Method.Option.Icon.Check />
                                                                              </Method.Option.Icon.Wrapper>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                      </Method.Option.Icon.Fade>
                                                                  )}
                                                              </Method.Option.Wrapper>
                                                          </Method.NestedContent>
                                                      )
                                                  })
                                                : ''}

                                            {paymentMethods.map(({ name, id, formName }) => {
                                                const isSelected = selectedPaymentMethod?.name === name
                                                const isDisabled =
                                                    isPayingWithOnlyAccountCredit || (formName === 'eftForm' && accountCreditToApply.amount)

                                                return (
                                                    <div key={id}>
                                                        <Method.Option.Wrapper
                                                            type='button'
                                                            key={name}
                                                            className={`${isSelected ? 'active' : ''}${isDisabled ? ' disabled' : ''}`}
                                                            aria-current={isSelected ? 'page' : undefined}
                                                            onClick={() => {
                                                                if (isDisabled) return
                                                                setSelectedPaymentMethodId(id)
                                                            }}
                                                        >
                                                            <Method.Option.Label>{name}</Method.Option.Label>
                                                            {isDisabled ? (
                                                                ''
                                                            ) : (
                                                                <Method.Option.Icon.Fade when={selectedPaymentMethod}>
                                                                    {isSelected && (
                                                                        <Method.Option.Icon.Wrapper>
                                                                            <Method.Option.Icon.Check />
                                                                        </Method.Option.Icon.Wrapper>
                                                                    )}
                                                                </Method.Option.Icon.Fade>
                                                            )}
                                                        </Method.Option.Wrapper>
                                                        {isSelected && !isDisabled ? (
                                                            <Method.NestedContent>
                                                                <Method.NestedForm>
                                                                    <Payment.Fade hidden={isDisabled}>
                                                                        {(() => {
                                                                            switch (selectedPaymentMethod?.formName) {
                                                                                case 'linkTypeForm':
                                                                                    return (
                                                                                        <LinkTypeForm
                                                                                            accountCreditToApply={accountCreditToApply.amount}
                                                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                                                            setTemplate={setTemplate}
                                                                                        />
                                                                                    )
                                                                                case 'eftForm':
                                                                                    return renderGenericPaymentMethodDescription(
                                                                                        selectedPaymentMethod?.name || '',
                                                                                        selectedPaymentMethod?.instructions || ''
                                                                                    )
                                                                                case 'creditCardForm':
                                                                                    return (
                                                                                        <CreditCardForm
                                                                                            accountCreditToApply={accountCreditToApply.amount}
                                                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                                                        />
                                                                                    )
                                                                                case 'tokenTypeForm':
                                                                                    return (
                                                                                        <TokenTypeForm
                                                                                            accountCreditToApply={accountCreditToApply.amount}
                                                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                                                            setTemplate={setTemplate}
                                                                                        />
                                                                                    )
                                                                                default:
                                                                                    return ''
                                                                            }
                                                                        })()}
                                                                    </Payment.Fade>
                                                                </Method.NestedForm>
                                                            </Method.NestedContent>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </Method.Wrapper>
                                    </Payment.Body>
                                </Payment.Base>
                            )}
                            {invoice && renderTotalSummary()}
                        </Invoice.Footer>
                        <Invoice.Actions>
                            <Invoice.Left>
                                <Invoice.Action
                                    type='button'
                                    onClick={async () => {
                                        setDownloadingInvoice(true)
                                        await downloadFile(`client/billing/invoices/${invoice?.id}/download`, `invoice_${invoice?.id}.pdf`).catch(
                                            () => void 0
                                        )
                                        setDownloadingInvoice(false)
                                    }}
                                >
                                    <span>{downloadingInvoice ? <Loader.Basic /> : 'Download'}</span>
                                    <InboxArrowDownIcon width='14px' />
                                </Invoice.Action>
                                <Invoice.Action
                                    type='button'
                                    loading={isSendingInvoiceEmail && 'Sending'}
                                    onClick={() => sendInvoiceEmail(Number(invoice?.id))}
                                >
                                    <span>Email Invoice</span>
                                    <AtSymbolIcon width='14px' />
                                </Invoice.Action>
                            </Invoice.Left>
                            <Invoice.Right>
                                <Button
                                    type='button'
                                    onClick={() => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))}
                                >
                                    Close
                                </Button>
                                {checkPaymentRequired()}
                            </Invoice.Right>
                        </Invoice.Actions>
                    </>
                ),
                conditions: {
                    state: appActiveInvoice.status,
                    action: () => dispatch(setAppActiveInvoice({ invoice: appActiveInvoice?.invoice, status: false }))
                }
            }
    }

    return <LightboxComponent {...conditionalProps} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
