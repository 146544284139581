/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TStyledFlexProps = {
    gap?: number | boolean
    rowGap?: number | boolean
    columnGap?: number | boolean
    $direction?: boolean | 'row' | 'column' | 'row-reverse' | 'column-reverse'
    justify?: boolean | 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
    align?: boolean | 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
    wrap?: boolean | 'nowrap' | 'wrap' | 'wrap-reverse'
    fullWidth?: boolean
    fullHeight?: boolean
    grow?: number

    /**
     * Object for applying the flex-grow property to a child. The first value is the nth-child and the second value is the flex-grow value.
     */
    growChild?: [nthChild: number, grow: number]

    /**
     * Object for applying the flex-grow property to multiple children. The key is the nth-child and the value is the flex-grow value.
     */
    growChildren?: Record<number, number>
}

type Flex = <A extends React.FC<ExpectedAny> | keyof React.JSX.IntrinsicElements = 'div'>(
    props: Omit<TStyledFlexProps, '$direction' | 'direction'> & {
        /**
         * React component to wrap the children with. This component must accept children as a prop as
         * it will be passed the children prop internally.
         */
        as?: A

        /**
         * The direction of the flex container.
         */
        direction?: TStyledFlexProps['$direction']

        children: React.ReactNode
        className?: string

        /**
         * Intrinsic properties of the underlying element.
         */
        intrinsic?: Utilities.Prettify<Utilities.AdvanceInferReactProps<NoInfer<A>>>
    }
) => React.ReactElement

/**********************************************************************************************************
 *   STYLED COMPONENT START
 **********************************************************************************************************/
const StyledFlex = styled.div<TStyledFlexProps>`
    display: flex;

    ${({ gap }) => gap && `gap: ${gap}px;`}
    ${({ $direction }) => $direction && `flex-direction: ${$direction};`}
    ${({ justify }) => justify && `justify-content: ${justify};`}
    ${({ align }) => align && `align-items: ${align};`}
    ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
    ${({ fullWidth }) => fullWidth && `width: 100%;`}
    ${({ fullHeight }) => fullHeight && `height: 100%;`}
    ${({ rowGap }) => rowGap && `row-gap: ${rowGap}px;`}
    ${({ columnGap }) => columnGap && `column-gap: ${columnGap}px;`}
    ${({ grow }) => grow && `flex-grow: ${grow};`}
    ${({ growChild }) => {
        if (!growChild) return

        const [nthChild, grow] = growChild
        return css`
            & > *:nth-child(${nthChild}) {
                flex-grow: ${grow};
            }
        `
    }}
    ${({ growChildren }) => {
        if (!growChildren) return

        return Object.entries(growChildren).map(([nthChild, grow]) => {
            return css`
                & > *:nth-child(${nthChild}) {
                    flex-grow: ${grow};
                }
            `
        })
    }}
`
/**********************************************************************************************************
 *   STYLED COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
/**
 * Flex is a utility component that allows you to create a flex container with a variety of options without needing to create
 * a new class or Styled component.
 */
export const Flex: Flex = ({ direction, intrinsic, ...props }) => <StyledFlex $direction={direction} {...props} {...(intrinsic as ExpectedAny)} />
