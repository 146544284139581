/***** BASE IMPORTS *****/
import React, { createContext, useContext } from 'react'

/***** TYPE DEFINITIONS *****/
type TextContext = true
type As = keyof React.ReactHTML | React.ComponentType<ExpectedAny> | undefined

/***** CONTEXT *****/
/**
 * The TextContext is not intended for us outside of the Text component. It is used to determine if the Text component is being used inside of a block element, and if so, it will render as a span instead of a paragraph.
 */
export const TextContext = createContext<TextContext | null>(null)

/***** HOOK START *****/
export const useResolveTextType = (as: As) => {
    /***** HOOKS *****/
    const inContext = !!useContext(TextContext)

    /***** RENDER *****/
    return as ?? (inContext ? 'span' : 'p')
}
