/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { DateTime } from 'luxon'
import { MutableRefObject } from 'react'
import { toast } from 'react-hot-toast'
import { Navigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { INVOICE_STATUS, SERVICE_STATUS } from 'helpers/constants'
import globalCookies from 'helpers/cookies'
import { Account, Contact, CurrentAccount } from 'models/account'
import { DNSRecordType } from 'models/enums'
import { ICart } from 'models/shop/cart'
import type { IProductPrice } from 'models/shop/product'
import { store } from 'store/store'

/**********************************************************************************************************
 *   COMMON FUNCTIONS
 **********************************************************************************************************/
const select = (state: object = {}): object => state

const pageTransitionVariants = {
    initial: { opacity: 0, x: -100 },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.5,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    exit: { opacity: 0, x: 100 }
}

const componentTransitionVariants = {
    initial: { opacity: 0, x: -15 },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.5,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    exit: { opacity: 0, x: 15 }
}

const uid = (): string => {
    return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10)
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

// Scroll an element into view if the top of the element is above the top of the screen
function scrollIntoViewIfNeeded(ref: MutableRefObject<HTMLElement | null>) {
    if (ref.current && ref.current.getBoundingClientRect().top < 0) ref.current.scrollIntoView({ behavior: 'smooth' })
}

/**
 * used for voiding a function return.
 */
export const noop = () => {
    /* silence */
}

/*   VALIDATION
 **********************************************************************************************************/
function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
    const authStatus = select(store.getState()) // TODO: add to .app.appAuthorisationStatus
    const location = useLocation()

    if (!authStatus) {
        return <Navigate to='/login' state={{ from: location }} />
    }

    return children
}

/*   VALIDATION
 **********************************************************************************************************/
const regex = {
    password: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/,
    phone: /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    internationalPhone: /^(?:\+|\.| |[0-9])*$/,
    domain: /^[a-z0-9]+(-[a-z0-9]+)*$/i,
    domainWithExtension: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i,
    idnPunycodeDomain: /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,
    dateOfBirth: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
    ipv4: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi,
    ipv6: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi,
    specialCharacters: /[a-z0-9\- ]{0,10}[a-z0-9]$/,
    domainOrEmpty: /^\s*(([a-z0-9_]+(-[a-z0-9_]+)*[^._])(\.([a-z0-9_]+(-[a-z0-9_]+)*[^._]))*[^._]?|@|\s*)\s*$/i,
    email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
}

/*   ACCOUNT
 **********************************************************************************************************/
function mapToAccount(account: CurrentAccount): Account {
    return {
        ...account.account,
        is_primary: account.is_primary,
        expiry: account.expiry,
        role: account.role
    }
}
/*   DOMAIN CONTACT
 **********************************************************************************************************/
const DomainContact: Contact = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    organisation: '',
    address1: '',
    address2: '',
    suburb: '',
    postcode: '',
    state: 'VIC',
    country: 'AU'
}

/*   VALIDATION SCHEMA
 **********************************************************************************************************/

const isType = (type: DNSRecordType) => (value: DNSRecordType) => value === type

const DNSRecordSchema = Yup.object().shape({
    //srv labels include "_" which is not a valid character for a domain, therefore this is excluded
    hostname: Yup.string()
        .when('type', {
            is: (type: DNSRecordType) =>
                Object.keys(DNSRecordType)
                    .filter((type) => ['CNAME', 'NS'].includes(type))
                    .includes(type),
            then: (schema) =>
                schema
                    .matches(/^[^@]/, 'CNAME records cannot apply to the root domain')
                    .matches(regex.domainOrEmpty, 'Hostname must be a domain')
                    .required('Required')
        })
        .when('type', {
            is: (type: DNSRecordType) =>
                Object.keys(DNSRecordType)
                    .filter((type) => ['A', 'AAAA', 'MX'].includes(type))
                    .includes(type),
            then: (schema) => schema.matches(regex.domainOrEmpty, 'Hostname must be a domain')
        }),
    content: Yup.string()
        .when('type', {
            is: isType(DNSRecordType.A),
            then: (schema) => schema.matches(regex.ipv4, 'Content must be a valid IPv4 Address')
        })
        .when('type', {
            is: isType(DNSRecordType.AAAA),
            then: (schema) => schema.matches(regex.ipv6, 'Content must be a valid IPv6 Address')
        })
        .when('type', {
            is: isType(DNSRecordType.CNAME),
            then: (schema) => schema.matches(regex.idnPunycodeDomain, 'Target must be a domain')
        })
        .when('type', {
            is: isType(DNSRecordType.NS),
            then: (schema) => schema.matches(regex.domainWithExtension, 'Nameserver must be a domain')
        })
        .when('type', {
            is: isType(DNSRecordType.MX),
            then: (schema) => schema.matches(regex.domainWithExtension, 'Mail Server must be a domain')
        })
        .when('type', {
            is: (type: DNSRecordType) =>
                Object.keys(DNSRecordType)
                    .filter((type) => type !== 'SRV')
                    .includes(type),
            then: (schema) => schema.required('Required').nullable()
        }),
    weight: Yup.string().when('type', {
        is: 'SRV',
        then: (schema) => schema.required('Required')
    }),
    port: Yup.string().when('type', {
        is: 'SRV',
        then: (schema) => schema.required('Required')
    }),
    destination: Yup.string().when('type', {
        is: 'SRV',
        then: (schema) => schema.matches(regex.domainWithExtension, 'Destination must be a domain').required('Required')
    }),
    ttl: Yup.number().typeError('TTL must be a number').required('Required'),
    priority: Yup.number()
        .when('type', {
            is: 'MX',
            then: (schema) => schema.required('Required').typeError('Priority must be a number')
        })
        .when('type', {
            is: 'SRV',
            then: (schema) => schema.required('Required').typeError('Priority must be a number')
        })
})

/*   DATA FUNCTIONS
 **********************************************************************************************************/
function getInvoiceStatusColour(status = ''): string {
    switch (status) {
        case INVOICE_STATUS.PAID:
            return 'confirm'
        case INVOICE_STATUS.UNPAID:
            return 'alternative'
        case INVOICE_STATUS.DISPUTED:
        case INVOICE_STATUS.CANCELED:
        case INVOICE_STATUS.FRAUD:
            return 'attention'
        case INVOICE_STATUS.REFUNDED:
        case INVOICE_STATUS.DRAFT:
        default:
            return 'secondary'
    }
}

function getServiceStatusColour(status = ''): string {
    switch (status) {
        case SERVICE_STATUS.ACTIVE:
            return 'confirm'
        case SERVICE_STATUS.PENDING:
            return 'primary'
        case SERVICE_STATUS.TERMINATED:
        case SERVICE_STATUS.EXPIRED:
            return 'attention'
        case SERVICE_STATUS.SUSPENDED:
        case SERVICE_STATUS.CANCELLED:
        default:
            return 'secondary'
    }
}

function getRecordTypeColour(type = ''): string {
    switch (type) {
        case DNSRecordType.A:
            return 'primary'
        case DNSRecordType.AAAA:
            return 'attention'
        case DNSRecordType.CNAME:
            return 'alternative'
        case DNSRecordType.MX:
            return 'info'
        case DNSRecordType.SRV:
            return 'confirm'
        case DNSRecordType.NS:
        case DNSRecordType.SOA:
        case DNSRecordType.TXT:
        default:
            return 'secondary'
    }
}

function getStatesByCountryCode(countryCode = 'AU'): Array<{ state_code: string; state: string }> {
    if (countryCode === 'AU') {
        return [
            { state_code: 'ACT', state: 'Australian Capital Territory' },
            { state_code: 'NSW', state: 'New South Wales' },
            { state_code: 'QLD', state: 'Queensland' },
            { state_code: 'VIC', state: 'Victoria' },
            { state_code: 'SA', state: 'South Australia' },
            { state_code: 'WA', state: 'Western Australia' },
            { state_code: 'TAS', state: 'Tasmania' }
        ]
    }

    if (countryCode === 'NZ') {
        return [
            { state_code: 'NTL', state: 'Northland' },
            { state_code: 'AUK', state: 'Auckland' },
            { state_code: 'WKO', state: 'Waikato' },
            { state_code: 'BOP', state: 'Bay of Plenty' },
            { state_code: 'GIS', state: 'Gisborne' },
            { state_code: 'HKB', state: "Hawke's Bay" },
            { state_code: 'TKI', state: 'Taranaki' },
            { state_code: 'MWT', state: 'Manawatu-Whanganui' },
            { state_code: 'WGN', state: 'Wellington' },
            { state_code: 'TAS', state: 'Tasman' },
            { state_code: 'NSN', state: 'Nelson' },
            { state_code: 'MBH', state: 'Marlborough' },
            { state_code: 'WTC', state: 'West Coast' },
            { state_code: 'CAN', state: 'Canterbury' },
            { state_code: 'OTA', state: 'Otago' },
            { state_code: 'STL', state: 'Southland' }
        ]
    }

    return []
}

function getBillingCycle(name = ''): string {
    switch (name) {
        case 'Monthly':
            return '/mo'
        case 'Quarterly':
            return '/qr'
        case 'Semi-Annually':
            return '/sa'
        case 'Annually':
            return '/yr'
        case 'Biennially':
            return '/2 yrs'
        case 'Triennially':
            return '/3 yrs'
        case 'One Time':
            return ''
        case '1 Year':
            return '/1 yr'
        case '2 Years':
            return '/2 yrs'
        case '3 Years':
            return '/3 yrs'
        case '4 Years':
            return '/4 yrs'
        case '5 Years':
            return '/5 yrs'
        case '6 Years':
            return '/6 yrs'
        case '7 Years':
            return '/7 yrs'
        case '8 Years':
            return '/8 yrs'
        case '9 Years':
            return '/9 yrs'
        case '10 Years':
            return '/10 yrs'
        default:
            return '/mo'
    }
}

function getCreditCardImage(type = 'mastercard'): string {
    const acceptedCardTypes = ['amex', 'dinersclub', 'discover', 'hipercard', 'jcb', 'mastercard', 'unionpay', 'visa']

    if (type === 'american express') {
        return 'amex'
    }

    if (!acceptedCardTypes.includes(type)) {
        return 'placeholder'
    }

    return type
}

// Removes | character and anything that comes before it
function removeSkuFromConfigOptionName(optionName: string) {
    const splitOnPipe = optionName.split('|')
    return splitOnPipe.length > 1 ? splitOnPipe[1] : splitOnPipe[0]
}
// Check if all prices are $0.00 for a product
function isProductPricingZero(pricing: IProductPrice[]) {
    return pricing.every((priceObject) => priceObject.price === '0.00')
}

function getNumberFromStringWithCommas(stringWithCommas: string) {
    return Number(stringWithCommas.replaceAll(',', ''))
}

function getCartTotals(type: string, cart?: ICart) {
    if (!cart?.pricing) return defaultCurrency(0)

    switch (type) {
        case 'subtotal':
            return defaultCurrency(cart.pricing.subtotal)

        case 'discount':
            return defaultCurrency(cart.pricing.total_discount)

        case 'total_due':
            return defaultCurrency(cart.pricing.total_due)

        case 'total_tax':
            return defaultCurrency(cart.pricing.total_tax)

        default:
            return defaultCurrency(cart.pricing.total)
    }
}

function handleCheckboxQuantity(quantity: number | Array<string>): number {
    if (!Array.isArray(quantity)) return quantity
    return quantity.length >= 1 ? 1 : 0
}

/*   STRING FUNCTIONS
 **********************************************************************************************************/
function getInitials(fullName = ''): string {
    const nameArr = fullName.toUpperCase().split(' ')
    const initials = nameArr.map((name) => name.at(0));

    if (initials.length < 5) {
        return initials.join('')
    } else {
        return initials[0] + initials.splice(-4).join('');
    }
}

function removePropertyByKeys(object: object, keys: Array<string>): object {
    return Object.fromEntries(Object.entries(object).filter(([key]) => !keys.includes(key)))
}

function titleCase(str = ''): string {
    return str.replace(/[^a-zA-Z]/g, ' ').replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

function camelCase(string = ''): string {
    return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

function snakeToPascalCase(str = ''): string {
    const camelCas = str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
    return camelCas.charAt(0).toUpperCase() + camelCas.slice(1)
}

function snakeCase(string = ''): string {
    const matchString = string.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)

    if (!matchString) return string

    return matchString.map((x) => x.toLowerCase()).join('_')
}

function pluralise(amount: number, string: string, additional: string): string {
    return amount > 1 ? `${string}${additional}` : string
}

function getDomainName(domainName = ''): string {
    const values = domainName.replace('www.', '').toLowerCase().split('.')

    return values[0]
}

function isAUDomain(domainName = ''): boolean {
    const extension = domainName.split('.')

    return extension[extension.length - 1] === 'au'
}

function formatDescription(description = ''): string {
    let desc: string

    if (/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi.test(description.toLowerCase())) {
        desc = description.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi, ' <span style="color: #6B7280">$&</span>')
    } else {
        if (/[^!@][a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+/g.test(description)) {
            desc = description.replace(
                /[^!@][a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+/g,
                '<span style="color: #6B7280">$&</span>'
            )
        } else {
            desc = description
        }
    }

    return desc
}

function extractNumberFromString(string = ''): number {
    return parseInt(string.replace(/^\D+/g, ''), 10)
}

async function copyToClipboard(text = '') {
    try {
        await navigator.clipboard.writeText(text)
        const success = {
            detail: 'Copied Successfully',
            type: 'SUCCESS'
        }
        // @ts-ignore
        toast.custom(success, {
            duration: 5000
        })
    } catch (e) {
        const error = {
            detail: 'Failed to copy. Please check browser permissions',
            type: 'ERROR'
        }
        // @ts-ignore
        toast.custom(error, {
            duration: 5000
        })
    }
}

function parseISOToLocalString(date: string) {
    return parseISO(date).setLocale('en-AU').toLocaleString()
}

/**
 * Treats all dates as if they are expiring at the end of the day. So if something expires in the middle of the day, it will say "today", even if it is passed the expiry
 */
function daysRemainsUntilExpired(expiryDate?: string | undefined) {
    const expiryDiff = DateUtils.todayDiff(expiryDate)

    if (typeof expiryDiff === 'number') {
        const roundedExpiry = Math.floor(expiryDiff)

        if ((expiryDiff < 0 && expiryDiff > -1) || roundedExpiry === 0) {
            return 'Today'
        }

        return `${roundedExpiry} days`
    }

    return null
}

export const DateUtils = {
    /**
     * Returns a number representing the difference in days between a date and today.
     * If the date is in the past, the number will be negative.
     *
     * This function resets the hours/minutes/seconds to 0 so that only the day/month/year is compared.
     */
    todayDiff: (date: string | undefined, format: 'iso' | 'sql' = 'iso'): number | null => {
        if (!date) {
            return null
        }

        const autoParse = (date: string) => {
            switch (format) {
                case 'sql':
                    return parseSQL(date)
                case 'iso':
                default:
                    return parseISO(date)
            }
        }

        const expiry = autoParse(date).set({ hour: 23, minute: 59, second: 59 })
        const now = DateTime.now().setZone('Australia/Sydney')
        const { days: expiryDiff } = expiry.diff(now, 'days').toObject()

        return expiryDiff ? Math.floor(expiryDiff) : null
    },

    parseReadable: (date: string, type: 'ISO' | 'SQL' | 'Format' = 'ISO', format = ''): string => {
        const parseFunction = `from${type}` as const
        const parsed = DateTime[parseFunction](date, format)

        return parsed.isValid ? parsed.setLocale('en-AU').toLocaleString() : ''
    }
}

/*   OBJECT FUNCTIONS
 **********************************************************************************************************/
function isObject(value: unknown): boolean {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return true
    }

    return false
}

function isObjectEmpty(object: object = {}): boolean {
    return Object.keys(object).length === 0
}

function flattenObject(object: object = {}): object {
    const flattened = {}

    Object.keys(object).forEach((key: string) => {
        if (typeof object[key as keyof typeof object] === 'object' && object[key as keyof typeof object] !== null) {
            Object.assign(flattened, flattenObject(object[key as keyof typeof object]))
        } else {
            flattened[key as keyof typeof flattened] = object[key as keyof typeof object]
        }
    })

    return flattened
}

function renameKeys(keysMap: object = {}, object: object = {}): object {
    return Object.keys(object).reduce((acc, key) => {
        const renameObject = {
            [keysMap[key as keyof typeof keysMap] || key]: object[key as keyof typeof object]
        }

        return {
            ...acc,
            ...renameObject
        }
    }, {})
}

function removeUndefinedNullEmptyStringFromObject(object: object): object {
    return Object.keys(object).reduce((acc, key) => {
        if (
            object[key as keyof typeof object] !== null &&
            object[key as keyof typeof object] !== undefined &&
            object[key as keyof typeof object] !== ''
        ) {
            acc[key as keyof typeof acc] = object[key as keyof typeof object]
        }

        return acc
    }, {})
}

function replaceNullToEmptyString(object: object = {}): object {
    return Object.keys(object).reduce((acc, key) => {
        const replaceObject = {
            [key]:
                object[key as keyof typeof object] === null || object[key as keyof typeof object] === undefined
                    ? ''
                    : object[key as keyof typeof object]
        }

        return {
            ...acc,
            ...replaceObject
        }
    }, {})
}

function downloadFile(endpoint = '', fileName = ''): Promise<void> {
    const url = `${import.meta.env.VITE_API}${endpoint}`
    const token = globalCookies.get('XSRF-TOKEN')

    return fetch(url, {
        method: 'POST',
        headers: {
            'X-XSRF-TOKEN': token
        },
        credentials: 'include'
    })
        .then((response) => response.blob())
        .then((blob) => {
            const downloadURL = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }))

            window.open(downloadURL)

            const link = document.createElement('a')
            link.href = downloadURL
            link.setAttribute('download', fileName)

            document.body.appendChild(link)

            link.click()

            link.parentNode?.removeChild(link)

            return
        })
}

/*   STRING FUNCTIONS
 **********************************************************************************************************/
function isTwoArrayEquals<T>(a: T, b: T) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value: unknown, index: number) => value === b[index])
}

/*   TIMER FUNCTIONS
 **********************************************************************************************************/
function Timer(callback: () => void, delay = 0): object {
    let timerId: number
    let start: number
    let remaining: number = delay

    const pause = (): void => {
        window.clearTimeout(timerId)
        remaining -= Date.now() - start
    }

    const cancel = (): void => {
        window.clearTimeout(timerId)
    }

    const resume = (): void => {
        start = Date.now()
        window.clearTimeout(timerId)
        timerId = window.setTimeout(callback, remaining)
    }

    resume()

    return { cancel, pause, resume }
}

function secondsToDhms(seconds = 0) {
    const d = Math.floor(seconds / (3600 * 24))
    const h = Math.floor((seconds % (3600 * 24)) / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = Math.floor((seconds % 3600) % 60)

    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : ''
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''

    return (dDisplay + hDisplay + mDisplay + sDisplay).replace(/,\s*$/, '')
}

/*   DATE FUNCTIONS
 **********************************************************************************************************/
function parseISO(date: string): DateTime {
    return DateTime.fromISO(date)
}

function parseSQL(date: string): DateTime {
    return DateTime.fromSQL(date)
}

function parseDate(date: string): unknown {
    return DateTime.fromFormat(date, 'dd/MM/yyyy')
}

export function parseDateToReadable(dateTime: string): string {
    return DateTime.fromISO(dateTime).setLocale('en-AU').toLocaleString()
}

/*   CURRENCY FUNCTIONS
 **********************************************************************************************************/
function getMercuryInstanceDefaultCurrency() {
    const storeState = store.getState()
    const defaultCurrency = storeState.app.appSettings.currency.find((currency) => currency.is_default)
    return defaultCurrency
}

function defaultCurrency(number: string | number | undefined = 0) {
    const numberWithoutCommas = parseFloat(number.toString().replace(/,/g, ''))

    const defaultCurrency = getMercuryInstanceDefaultCurrency()
    const defaultCurrencyCode = defaultCurrency?.iso_code || 'AUD'

    return new Intl.NumberFormat(defaultCurrencyCode === 'AUD' ? 'en-AU' : undefined, {
        style: 'currency',
        currency: defaultCurrencyCode,
        minimumFractionDigits: 2
    }).format(numberWithoutCommas)
}

function getDefaultCurrencySymbol() {
    const defaultCurrency = getMercuryInstanceDefaultCurrency()
    const defaultCurrencySymbol = defaultCurrency?.symbol || '$'
    return defaultCurrencySymbol
}

function getDefaultCurrencyCode() {
    const defaultCurrency = getMercuryInstanceDefaultCurrency()
    const defaultCurrencyCode = defaultCurrency?.iso_code || 'AUD'
    return defaultCurrencyCode
}

export {
    camelCase,
    componentTransitionVariants,
    copyToClipboard,
    daysRemainsUntilExpired,
    defaultCurrency,
    DNSRecordSchema,
    DomainContact,
    downloadFile,
    extractNumberFromString,
    flattenObject,
    formatDescription,
    getBillingCycle,
    getCartTotals,
    getCreditCardImage,
    getDefaultCurrencyCode,
    getDefaultCurrencySymbol,
    getDomainName,
    getInitials,
    getInvoiceStatusColour,
    getNumberFromStringWithCommas,
    getRecordTypeColour,
    getServiceStatusColour,
    getStatesByCountryCode,
    handleCheckboxQuantity,
    isAUDomain,
    isObject,
    isObjectEmpty,
    isProductPricingZero,
    isTwoArrayEquals,
    mapToAccount,
    pageTransitionVariants,
    parseDate,
    parseISO,
    parseISOToLocalString,
    parseSQL,
    pluralise,
    PrivateRoute,
    regex,
    removePropertyByKeys,
    removeSkuFromConfigOptionName,
    removeUndefinedNullEmptyStringFromObject,
    renameKeys,
    replaceNullToEmptyString,
    scrollIntoViewIfNeeded,
    secondsToDhms,
    sleep,
    snakeCase,
    snakeToPascalCase,
    Timer,
    titleCase,
    uid
}

