import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useAddPromoCodeToCartMutation, useCreateCartMutation, useRemoveCartItemMutation, useUpdateCartItemsMutation } from 'api/shop'
import { IServiceConfigurationForm } from 'containers/shop/services/configure'
import { FormikProps } from 'formik'
import { getDomainName, sleep } from 'helpers/utils'
import { ICart, ICartCreateItem, ICartItem } from 'models/shop/cart'
import { IDomainAvailability } from 'models/shop/domain'
import { IPricing } from 'models/shop/shop'
import { Loader } from 'nxui/src'
import React, { RefObject, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { Style as S } from './selectDomain.style'

type TSelectDomain = React.FC<{
    item: IDomainAvailability
    cart: ICart | undefined
    selectedPricing: Array<{
        id: number
        selectedPrice: IPricing
    }>
    embedded: boolean
    serviceConfigRef?: RefObject<FormikProps<IServiceConfigurationForm>>
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OwnSelectDomain: TSelectDomain = ({ item, cart, selectedPricing, embedded, serviceConfigRef }) => {
    /***** STATE *****/
    const [selectingProductId, setSelectingProductIds] = useState<number | undefined>()
    const [deselectingProductId, setDeselectingProductIds] = useState<string | undefined>()

    /***** HOOKS *****/
    const { searchTerm, searchParamPromotion } = useAppSelector((state) => state.shop)
    const domain = getDomainName(searchTerm)

    /***** QUERIES *****/
    const [removeCartItem, { isLoading: isRemoveCartItemLoading }] = useRemoveCartItemMutation({ fixedCacheKey: 'remove-cart-item' })
    const [updateCartItems, { isLoading: isUpdateCartItemsLoading }] = useUpdateCartItemsMutation({ fixedCacheKey: 'update-cart-items' })
    const [createCart, { isLoading: isCreateCartLoading }] = useCreateCartMutation({ fixedCacheKey: 'OwnSelectDomain/update-cart-items' })
    const [addPromotion, { isLoading: isAddingPromotion }] = useAddPromoCodeToCartMutation({ fixedCacheKey: 'add-promotion' })

    /***** RENDER HELPERS *****/
    const cartItem = cart?.items?.find(
        (pendingCartItem) => pendingCartItem.product.id === item.product.id && pendingCartItem.name === `${domain}${item.product.name}`
    )

    const handleRemoveDomain = (cartItem: ICartItem, cart: ICart) => {
        setDeselectingProductIds(cartItem.item_uuid)
        removeCartItem({ uuid: cart.uuid, remove_items: [cartItem.item_uuid] })
            .finally(() => setDeselectingProductIds(undefined))
            .catch(() => void 0)
    }

    const handleAddDomain = async () => {
        try {
            setSelectingProductIds(item.product.id)

            const { 0: firstPrice } = item.pricing.filter((price) => price.type === 'register')
            const selectedProductPricing = selectedPricing.find((price) => price.id === item.product.id)

            const payloadItem: ICartCreateItem = {
                pending: true,
                order_type: 'register',
                product_id: item.product.id,
                is_premium: item?.premium,
                billing_cycle_id: selectedProductPricing
                    ? selectedProductPricing.selectedPrice.billing_cycle_id
                    : firstPrice.billing_cycle_id ?? firstPrice.billing_cycle.id,
                name: `${domain}${item.product.name}`
            }

            const itemHasSalePrice = item.pricing.find(
                ({ sale_price, billing_cycle_id }) => Boolean(sale_price) && billing_cycle_id === payloadItem.billing_cycle_id
            )

            if (cart?.uuid) {
                await updateCartItems({ uuid: cart?.uuid, items: [payloadItem] })

                /**
                 * - If we have a promotion to apply (from the query params)
                 * - This item has a sale price (therefore can be discounted using the promo)
                 * - The promotion is not already applied
                 */
                if (searchParamPromotion && itemHasSalePrice && cart.promo_code !== searchParamPromotion) {
                    await addPromotion({ uuid: cart.uuid, code: searchParamPromotion })
                }

                if (embedded && serviceConfigRef?.current) {
                    serviceConfigRef.current.setFieldValue('domain', payloadItem.name)
                    await sleep(100)
                    serviceConfigRef.current.handleSubmit()
                }
            } else {
                const { uuid } = await createCart({ items: [payloadItem] }).unwrap()

                if (searchParamPromotion && itemHasSalePrice && cart) {
                    await addPromotion({ uuid, code: searchParamPromotion })
                }
            }
        } catch (e) {
            // do nothing
        } finally {
            setSelectingProductIds(undefined)
        }
    }

    const isUpdating = (isUpdateCartItemsLoading || isCreateCartLoading) && selectingProductId === item.product.id
    const isRemoving = isRemoveCartItemLoading && deselectingProductId === cartItem?.item_uuid
    const isMutationLoading = isCreateCartLoading || isUpdateCartItemsLoading || isRemoveCartItemLoading || isAddingPromotion

    /***** RENDER *****/
    if (cart && cartItem) {
        return (
            <S.Anchor
                color={'select'}
                disabled={isMutationLoading}
                showDisabled={isMutationLoading && !isRemoving}
                icon={isRemoving ? <Loader.Basic /> : <CheckIcon />}
                onClick={() => handleRemoveDomain(cartItem, cart)}
            >
                {isRemoving ? 'Removing' : 'Selected'}
            </S.Anchor>
        )
    }

    return (
        <S.Anchor
            showDisabled={isMutationLoading && !isUpdating}
            color={'select'}
            disabled={isMutationLoading}
            icon={isUpdating ? <Loader.Basic /> : <PlusIcon />}
            onClick={handleAddDomain}
        >
            {isUpdating || isAddingPromotion ? 'Adding...' : 'Select'}
        </S.Anchor>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
