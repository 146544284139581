import { Theme } from 'nxui/src'
import { forwardRef, type HTMLAttributes } from 'react'
import styled from 'styled-components'
import { CombinedTButtonProps, TAnchorButtonProps, TLinkButtonProps, TOnClickButtonProps, TSubmitButtonProps, _Button } from '../base'
import { AnchorStyles } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// For the anchor variation
type TAnchorProps = CombinedTButtonProps

// Helper to easily define a button
type Button<T extends { type: string }> = React.ForwardRefExoticComponent<
    Omit<T, 'type'> &
        React.RefAttributes<HTMLElement | ((el: HTMLElement) => void)> & {
            intrinsic?: HTMLAttributes<HTMLElement>
        }
>

// Define the button variants (without type as the component defines this)
type StandardAnchor = Button<TAnchorButtonProps>
type SubmitAnchor = Button<TSubmitButtonProps>
type OnClickAnchor = Button<TOnClickButtonProps>
type LinkAnchor = Button<TLinkButtonProps>

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
const StyledAnchor = styled(_Button)<TAnchorProps>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    letter-spacing: 0.25;
    font-size: 12px;

    ${Theme.Functions.setFont('Inter', 600)}
    ${Theme.Functions.outline('none')};
    ${Theme.Functions.animation('all', 500)};
    ${({ fullwidth }) => fullwidth && 'width: 100%;'}

    :focus {
        ${Theme.Functions.outline('none')};
    }

    .icon {
        width: 14px;
        height: 14px;
        margin: 0 -4px 0 6px;
    }

    ${AnchorStyles.Color}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _StandardAnchor: StandardAnchor = forwardRef(({ children, intrinsic, ...props }, ref) => {
    return (
        <StyledAnchor ref={ref} type='anchor' {...(props as ExpectedAny)} {...(intrinsic as ExpectedAny)}>
            {children}
        </StyledAnchor>
    )
})

export const _OnClickAnchor: OnClickAnchor = forwardRef(({ children, intrinsic, ...props }, ref) => {
    return (
        <StyledAnchor ref={ref} type='onclick' {...(props as ExpectedAny)} {...(intrinsic as ExpectedAny)}>
            {children}
        </StyledAnchor>
    )
})

export const _SubmitAnchor: SubmitAnchor = forwardRef(({ children, intrinsic, ...props }, ref) => {
    return (
        <StyledAnchor ref={ref} type='submit' {...(props as ExpectedAny)} {...(intrinsic as ExpectedAny)}>
            {children}
        </StyledAnchor>
    )
})

export const _LinkAnchor: LinkAnchor = forwardRef(({ children, intrinsic, ...props }, ref) => {
    return (
        <StyledAnchor ref={ref} type='link' {...(props as ExpectedAny)} {...(intrinsic as ExpectedAny)}>
            {children}
        </StyledAnchor>
    )
})
