import { Functions } from 'nxui/src/theme/functions'

const Section = {
    login: true,
    dashboard: true,
    billing: true,
    support: true,
    website: false,
    service: {
        overview: true,
        general: true,
        addons: true,
        server: true,
        cancel: true,
        login: true
    },
    shop: {
        shop: true,
        domains: true,
        products: true
    },
    domain: {
        admin: true,
        contacts: true,
        dns: true,
        general: true
    },
    account: {
        profile: true,
        details: true,
        twofactor: true,
        security: true,
        users: true,
        log: true
    }
}

const Provider = {
    application_name: 'Nexigen Client Area',
    business_number: '90 637 030 329',
    provider_name: 'Nexigen Digital',
    email_billing: 'billing@nexigen.digital',
    email_info: 'info@nexigen.digital',
    email_support: 'support@nexigen.digital',
    logo_url: 'https://nexigen.digital/wp-content/uploads/2020/06/nexigen.svg',
    url: 'https://nexigen.digital',
    default_country: 'AU',
    allowed_countries: '',
    favicon_url: 'images/brand/logo.png',
    terms_and_conditions: 'terms and conditions',
    website_url: 'https://nexigen.digital',
    captcha_public: ''
}

const Website = {
    tld_list: '.online,.com.au,.store',
    hero_title: 'The official Bootie Boi domain name registrar',
    hero_subtitle: 'Get your custom domain today.',
    information: [
        [
            'plus',
            'Free WHOIS Privacy',
            "Keep your private contact information hidden from prying eyes with our WHOIS privacy service. Most registrars charge for this service; we believe your privacy shouldn't come at a price."
        ],
        [
            'plus',
            'URL Forwarding',
            'Quickly and easily point your domain somewhere else with free URL Forwarding. Our URL Forwarder supports permanent, temporary, and masked forwards.'
        ],
        [
            'plus',
            'DNS Management',
            "With free DNS management powered by Cloudflare, you can rest easy knowing your site's DNS will be robust and available."
        ]
    ],
    information_title: 'Why choose us?',
    about_us: 'About Us',

    // contact information
    address: '',
    phone_billing: '',
    phone_info: '',
    phone_support: ''
}

type SingleCurrency = {
    is_default: boolean
    iso_code: string
    symbol: string
    name: string
}

const Currency: SingleCurrency[] = []

enum EColors {
    Primary = '#3e7bfa',
    Success = '#5AC065',
    Warning = '#fDAC42',
    Accent = '#AC5DD9',
    Error = '#FF5C5C',
    Info = '#3E7BFA',
    Light = '#F2F2F5',
    Dark = '#111827',
    White = '#FFFFFF',
    Black = '#000000'
}

const Shadows = {
    '--shadow-100': `0px 2px 5px ${Functions.hexToRGBA(EColors.Dark, 0.07)}`,
    '--shadow-200': `
        0px 0.6px 1.3px ${Functions.hexToRGBA(EColors.Dark, 0.035)},
        0px 5px 10px ${Functions.hexToRGBA(EColors.Dark, 0.07)}
    `,
    '--shadow-300': `
        0px 0.7px 1.3px ${Functions.hexToRGBA(EColors.Dark, 0.028)},
        0px 2.2px 4.5px ${Functions.hexToRGBA(EColors.Dark, 0.042)},
        0px 10px 20px ${Functions.hexToRGBA(EColors.Dark, 0.07)}
    `,
    '--shadow-400': `
        0px 0.7px 1.4px ${Functions.hexToRGBA(EColors.Dark, 0.024)},
        0px 1.9px 3.8px ${Functions.hexToRGBA(EColors.Dark, 0.035)},
        0px 4.5px 9px ${Functions.hexToRGBA(EColors.Dark, 0.046)},
        0px 15px 30px ${Functions.hexToRGBA(EColors.Dark, 0.07)}
    `,
    '--shadow-500': `
        0px 0.7px 1.4px ${Functions.hexToRGBA(EColors.Dark, 0.022)},
        0px 1.7px 3.5px ${Functions.hexToRGBA(EColors.Dark, 0.031)},
        0px 3.5px 7.1px ${Functions.hexToRGBA(EColors.Dark, 0.039)},
        0px 7.3px 14.6px ${Functions.hexToRGBA(EColors.Dark, 0.048)},
        0px 20px 40px ${Functions.hexToRGBA(EColors.Dark, 0.07)}
    `,
    '--shadow-600': `
        0px 0.8px 1.7px ${Functions.hexToRGBA(EColors.Dark, 0.02)},
        0px 2px 4px ${Functions.hexToRGBA(EColors.Dark, 0.028)},
        0px 3.8px 7.5px ${Functions.hexToRGBA(EColors.Dark, 0.035)},
        0px 6.7px 13.4px ${Functions.hexToRGBA(EColors.Dark, 0.042)},
        0px 12.5px 25.1px ${Functions.hexToRGBA(EColors.Dark, 0.05)},
        0px 30px 60px ${Functions.hexToRGBA(EColors.Dark, 0.07)}
    `
}

const DropShadows = {
    '--drop-shadow-100': `drop-shadow(0px 2px 1px ${Functions.hexToRGBA(EColors.Dark, 0.07)})`,
    '--drop-shadow-200': `
        drop-shadow(0px 0.6px 1.3px ${Functions.hexToRGBA(EColors.Dark, 0.035)})
        drop-shadow(0px 5px 2px ${Functions.hexToRGBA(EColors.Dark, 0.07)})
    `,
    '--drop-shadow-300': `
        drop-shadow(0px 0.7px 1.3px ${Functions.hexToRGBA(EColors.Dark, 0.028)})
        drop-shadow(0px 2.2px 2px ${Functions.hexToRGBA(EColors.Dark, 0.042)})
        drop-shadow(0px 10px 3px ${Functions.hexToRGBA(EColors.Dark, 0.07)})
    `,
    '--drop-shadow-400': `
        drop-shadow(0px 0.7px 1.4px ${Functions.hexToRGBA(EColors.Dark, 0.024)})
        drop-shadow(0px 1.9px 2px ${Functions.hexToRGBA(EColors.Dark, 0.035)})
        drop-shadow(0px 4.5px 3px ${Functions.hexToRGBA(EColors.Dark, 0.046)})
        drop-shadow(0px 15px 4px ${Functions.hexToRGBA(EColors.Dark, 0.07)})
    `,
    '--drop-shadow-500': `
        drop-shadow(0px 0.7px 1.4px ${Functions.hexToRGBA(EColors.Dark, 0.022)})
        drop-shadow(0px 1.7px 2px ${Functions.hexToRGBA(EColors.Dark, 0.031)})
        drop-shadow(0px 3.5px 3px ${Functions.hexToRGBA(EColors.Dark, 0.039)})
        drop-shadow(0px 7.3px 4px ${Functions.hexToRGBA(EColors.Dark, 0.048)})
        drop-shadow(0px 20px 5px ${Functions.hexToRGBA(EColors.Dark, 0.07)})
    `,
    '--drop-shadow-600': `
        drop-shadow(0px 0.8px 1px ${Functions.hexToRGBA(EColors.Dark, 0.02)})
        drop-shadow(0px 2px 2px ${Functions.hexToRGBA(EColors.Dark, 0.028)})
        drop-shadow(0px 3.8px 3px ${Functions.hexToRGBA(EColors.Dark, 0.035)})
        drop-shadow(0px 6.7px 4px ${Functions.hexToRGBA(EColors.Dark, 0.042)})
        drop-shadow(0px 12.5px 5px ${Functions.hexToRGBA(EColors.Dark, 0.05)})
        drop-shadow(0px 30px 6px ${Functions.hexToRGBA(EColors.Dark, 0.07)})
    `
}

const Theme = {
    'breakpoints-xs': 480,
    'breakpoints-sm': 640,
    'breakpoints-md': 768,
    'breakpoints-lg': 1024,
    'breakpoints-xl': 1280,
    'breakpoints-2xl': 1536,
    'breakpoints-3xl': 2160,
    transparent: 'transparent',
    'primary--100': '#4f46e5',
    'primary--200': '#3739B8',
    'primary--300': '#0A1E42',
    'secondary--100': '#FFFFFF',
    'secondary--200': '#F9FAFB',
    'highlight--100': '#22D3EE',
    'confirm--100': '#4caf50',
    'confirm--200': '#418344',
    'warning--100': '#FFB812',
    'warning--200': '#C25D04',
    'error--100': '#FF4242',
    'error--200': '#e42a2a',
    'info--100': '#3381FF',
    'info--200': '#0F46BD',
    'background--000': '#FFFFFF',
    'background--100': '#F9FAFB',
    'background--200': '#F3F4F6',
    'background--300': '#E5E7EB',
    'background--400': '#374151',
    'border--000': '#E5E7EB',
    'border--100': '#D1D5DB',
    'border--200': '#111827',
    'icon--100': '#9CA3AF',
    'icon--200': '#A0A6B2',
    'icon--300': '#6B7280',
    'text--100': '#6B7280',
    'text--200': '#374151',
    'text--300': '#111827',
    'white--100': '#FFFFFF',
    'black--100': '#111827',
    'black--200': '#000000',
    'gray--100': '#E5E7EB',
    'gray--200': '#D1D5DB',
    'gradient-primary--100': '#4f46e5',
    'gradient-primary--200': '#6875f5',
    ...Shadows,
    ...DropShadows
} as const

export type ISection = typeof Section
export type IProvider = typeof Provider
export type ITheme = typeof Theme
export type ICurrency = typeof Currency
export type IWebsite = typeof Website | undefined

export default {
    Section,
    Provider,
    Theme,
    Currency,
    Website
}
