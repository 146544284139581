import type { TTheme } from 'declarations/styled'
import styled from 'styled-components'

/**********************************************************************************************************
 *   STYLE START
 **********************************************************************************************************/
export const StyledText = styled.p<{
    $size?: number
    $color?: keyof TTheme
    $bold?: boolean
    $semiBold?: boolean
    $medium?: boolean
    $regular?: boolean
    $italic?: boolean
    $underline?: boolean
    $strike?: boolean
    $uppercase?: boolean
    $capitalise?: boolean
    $alignLeft?: boolean
    $alignCenter?: boolean
    $alignRight?: boolean
    $primary?: boolean
    $secondary?: boolean
    $fullWidth?: boolean
    $gray?: boolean
}>`
    font-family: Inter;

    font-size: ${({ $size }) => ($size ? `${$size}px` : 'inherit')};
    font-style: ${({ $italic }) => ($italic ? 'italic' : 'normal')};

    font-weight: ${({ $bold, $semiBold, $medium, $regular }) => {
        if ($bold) return 'bold'
        if ($semiBold) return '600'
        if ($medium) return '500'
        if ($regular) return '400'
        return 'inherit'
    }};

    text-decoration: ${({ $underline, $strike }) => {
        if ($underline && $strike) return 'underline line-through'
        if ($underline) return 'underline'
        if ($strike) return 'line-through'
        return 'none'
    }};

    text-transform: ${({ $uppercase, $capitalise }) => {
        if ($uppercase) return 'uppercase'
        if ($capitalise) return 'capitalize'
        return 'none'
    }};

    text-align: ${({ $alignLeft, $alignCenter, $alignRight }) => {
        if ($alignLeft) return 'left'
        if ($alignCenter) return 'center'
        if ($alignRight) return 'right'
        return 'inherit'
    }};

    color: ${({ $color, $primary, $secondary, $gray, theme }) => {
        if ($color) return theme[$color]
        if ($primary) return theme['primary--100']
        if ($secondary) return theme['secondary--100']
        if ($gray) return theme['text--100']
        return 'inherit'
    }};

    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`
