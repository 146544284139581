/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

/**********************************************************************************************************
 *   TYPE
 **********************************************************************************************************/
type SetValue<T> = Dispatch<SetStateAction<T>>

// eslint-disable-next-line no-undef
interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean
}

/**********************************************************************************************************
 *   HOOK
 **********************************************************************************************************/
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

function useIsMounted() {
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    return useCallback(() => isMounted.current, [])
}

function useEventListener<T extends HTMLElement = HTMLDivElement>(
    eventName: keyof WindowEventMap | string,
    handler: (event: Event) => void,
    element?: RefObject<T>
) {
    const saveHandler = useRef<(event: Event) => void>()

    useEffect(() => {
        const targetElement: T | Window = element?.current || window

        if (!(targetElement && targetElement.addEventListener)) {
            return
        }

        if (saveHandler.current !== handler) {
            saveHandler.current = handler
        }

        const eventListener = (event: Event) => {
            if (saveHandler?.current) {
                saveHandler.current(event)
            }
        }

        targetElement.addEventListener(eventName, eventListener)

        return () => {
            targetElement.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element, handler])
}

/**
 * @param key - The key to store and retrieve the value from localStorage
 * @param initialValue - The initial value to use if the key is not in localStorage
 * @param parser - An optional function to parse the value from a string to the desired type. Typically the base JSON parser can be used
 *                 but for strings stored as strings, etc, this can be used to return the value as is
 */
function useLocalStorage<T>(key: string, initialValue: T, parser?: (value: string) => T): [T, SetValue<T>] {
    const readValue = (): T => {
        if (typeof window === 'undefined') {
            return initialValue
        }

        try {
            const item = window.localStorage.getItem(key)
            return item ? ((parser ?? parseJSON)(item) as T) : initialValue
        } catch (error) {
            console.warn(`Error reading localStorage key "${key}":`, error)
            return initialValue
        }
    }

    const [storeValue, setStoreValue] = useState<T>(readValue)

    const setValue: SetValue<T> = (value) => {
        if (typeof window === 'undefined') {
            console.warn(`Tried setting localStorage key "${key}" even though environment is not a client`)
        }

        try {
            const newValue = value instanceof Function ? value(storeValue) : value

            window.localStorage.setItem(key, JSON.stringify(newValue))

            setStoreValue(newValue)

            window.dispatchEvent(new Event('local-storage'))
        } catch (error) {
            console.warn(`Error setting localStorage key "${key}":`, error)
        }
    }

    useEffect(() => {
        setStoreValue(readValue())
    }, [])

    const handleStorageChange = () => {
        setStoreValue(readValue())
    }

    useEventListener('storage', handleStorageChange)
    useEventListener('local-storage', handleStorageChange)

    return [storeValue, setValue]
}

function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])

    return debouncedValue
}

function useIntersectionObserver(
    elementRef: RefObject<Element>,
    { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined {
    const [entry, setEntry] = useState<IntersectionObserverEntry>()

    const frozen = entry?.isIntersecting && freezeOnceVisible

    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry)
    }

    useEffect(() => {
        const node = elementRef?.current
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observerParams = { threshold, root, rootMargin }
        const observer = new IntersectionObserver(updateEntry, observerParams)

        observer.observe(node)

        return () => observer.disconnect()
    }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])

    return entry
}

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

function useMeta() {
    const isMounted = useIsMounted()
    const [searchParams, setSearchParams] = useSearchParams()
    const sort = searchParams.get('sort_by')
    const searchParam = searchParams.get('search_by')
    const [search, setSearch] = useState(searchParam)

    const searchValue = useDebounce(search, 500)

    const handleSort = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (sortBy: any) => {
            if (isMounted() && sortBy.length > 0) {
                const { id, desc } = sortBy[0]
                searchParams.set('sort_by', `${desc ? '-' : ''}${id}`)
                setSearchParams(searchParams)
            }
        },
        [sort]
    )

    const handleSearch = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (globalFilter: any) => {
            setSearch(globalFilter)

            if (!globalFilter && searchParam) {
                searchParams.delete('search_by')
                setSearchParams(searchParams)
            }

            if (searchValue && globalFilter) {
                searchParams.set('page', '1')
                searchParams.set('search_by', searchValue)
                setSearchParams(searchParams)
            }
        },
        [searchValue, searchParam]
    )

    return { handleSort, handleSearch }
}

const useFocus = (): [RefObject<HTMLInputElement>, () => void] => {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = useCallback(() => {
        if (htmlElRef.current) htmlElRef.current.focus()
    }, [htmlElRef])

    return useMemo(() => [htmlElRef, setFocus], [htmlElRef, setFocus])
}

export function parseJSON<T>(value: string | null): T | undefined {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch (error) {
        console.error('parsing error on', { value })
        return undefined
    }
}

export {
    useDebounce,
    useEventListener,
    useFocus,
    useIntersectionObserver,
    useInterval,
    useIsMounted,
    useIsomorphicLayoutEffect,
    useLocalStorage,
    useMeta,
    usePrevious
}
