/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import _ from 'lodash'
import { forwardRef } from 'react'
import { Loader } from '../NXLoader'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type TButtonBaseProps = {
    children: React.ReactNode
    className?: string
    fullwidth?: boolean
    disabled?: boolean
    loading?: boolean | string
    type?: 'onclick' | 'submit' | 'link' | 'anchor'

    /**
     * Note: Some colors are specific to the anchor or button variants. Further type adjustments may be required
     */
    color?: 'primary' | 'error' | 'flat' | 'secondary' | 'alternate' | 'select'
    align?: 'left' | 'center' | 'right'
}

export type TOnClickButtonProps = TButtonBaseProps & {
    onClick: (e: React.MouseEvent) => void
    type: 'onclick'
}

export type TSubmitButtonProps = TButtonBaseProps & {
    type: 'submit'
}

export type TLinkButtonProps = TButtonBaseProps & {
    to: string
    type: 'link'
}

export type TAnchorButtonProps = TButtonBaseProps & {
    href: string
    type: 'anchor'
    target?: string
}

export type CombinedTButtonProps = UnionToIntersection<
    Omit<TOnClickButtonProps, 'type'> | Omit<TSubmitButtonProps, 'type'> | Omit<TLinkButtonProps, 'type'> | Omit<TAnchorButtonProps, 'type'>
>

type TButtonProps = TOnClickButtonProps | TSubmitButtonProps | TLinkButtonProps | TAnchorButtonProps
type Button = React.ForwardRefExoticComponent<TButtonProps & React.RefAttributes<HTMLElement | ((el: HTMLElement) => void)>>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Button component providing the base functionality for a button, link, or anchor tag.
 * The styling for the Anchor and Link tag are provided as compound components to the Button
 */
export const _Button = forwardRef<HTMLElement, TButtonProps>(({ children, className, loading, disabled, ...props }, ref) => {
    /***** FUNCTIONS *****/
    const getChildren = () => {
        switch (true) {
            case typeof loading === 'boolean' && loading:
                return 'loading'
            case !!loading:
                return <Loader message={loading ? loading : children} />
            default:
                return children
        }
    }

    /***** RENDER HELPERS *****/
    const domProps = _.omit(props, ['fullwidth']) as ExpectedAny
    const baseProps = {
        className,
        'aria-disabled': disabled || !!loading,
        ref
    }

    /***** RENDER *****/
    switch (props.type) {
        case 'anchor': {
            return (
                <a {...baseProps} {...domProps}>
                    {getChildren()}
                </a>
            )
        }
        case 'link': {
            return (
                <Link {...baseProps} {...domProps}>
                    {getChildren()}
                </Link>
            )
        }
        case 'onclick': {
            return (
                <button {...baseProps} {...domProps} type='button' disabled={disabled || !!loading}>
                    {getChildren()}
                </button>
            )
        }
        case 'submit': {
            return (
                <button {...baseProps} {...domProps} type='submit' disabled={disabled || !!loading}>
                    {getChildren()}
                </button>
            )
        }
        default: {
            return null
        }
    }
}) as Button
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
