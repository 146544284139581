import classNames from 'classnames'
import { TextContext, useResolveTextType } from 'components/utilities/Text/context'
import { StyledText } from 'components/utilities/Text/style'
import type { Text as TextType } from 'components/utilities/Text/types'
import _ from 'lodash'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Text: TextType = ({
    children,
    as,
    className,
    intrinsic,
    style,
    size: $size,
    color: $color,
    bold: $bold,
    medium: $medium,
    regular: $regular,
    italic: $italic,
    underline: $underline,
    strike: $strike,
    uppercase: $uppercase,
    capitalise: $capitalise,
    'semi-bold': $semiBold,
    'align-left': $alignLeft,
    'align-center': $alignCenter,
    'align-right': $alignRight,
    'full-width': $fullWidth,

    // colors
    primary: $primary,
    secondary: $secondary,
    gray: $gray
}) => {
    /***** HOOKS *****/
    const resolvedAs = useResolveTextType(as)

    /***** RENDER HELPERS *****/
    const composedClassNames = classNames('Text', className, intrinsic?.className)

    const composedProps: ExpectedAny = {
        style,
        className: composedClassNames,

        /***** CUSTOM PROPS *****/
        $size,
        $color,
        $bold,
        $semiBold,
        $medium,
        $regular,
        $italic,
        $underline,
        $strike,
        $uppercase,
        $capitalise,
        $alignLeft,
        $alignCenter,
        $alignRight,
        $primary,
        $secondary,
        $gray,
        $fullWidth,

        ..._.omit(intrinsic, ['className', 'style', 'as'])
    }

    /***** RENDER *****/
    return (
        <TextContext.Provider value>
            <StyledText as={resolvedAs} {...composedProps}>
                {children}
            </StyledText>
        </TextContext.Provider>
    )
}
