/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useState } from 'react'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Lightbox } from 'nxui/src'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

export const paymentProcessingMessage =
    'Payment has been successfully submitted for processing. Note that it may take up to a few minutes for your invoice to be processed.'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function LinkPaymentReturnLightbox() {
    const { orderId, processingStatus } = useAppSelector((state) => state.app.appLinkTypePaymentState)

    const isLinkTypePaymentStillProcessing = processingStatus === 'in-progress'

    const [isLinkTypePaymentLightboxOpen, setIsLinkTypePaymentLightboxOpen] = useState(isLinkTypePaymentStillProcessing && !orderId)

    return (
        <Lightbox
            type='confirm'
            icon='info'
            title='Processing Payment'
            description={paymentProcessingMessage}
            close={() => setIsLinkTypePaymentLightboxOpen(false)}
            conditions={{
                state: isLinkTypePaymentLightboxOpen,
                action: () => setIsLinkTypePaymentLightboxOpen(false)
            }}
        />
    )
}

export default LinkPaymentReturnLightbox
