/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * Wrap with component expects another component (that can render children) and will wrap the children
 * in that component if `wrap` is true. If `wrap` is false, it will return the children as is.
 */
type TWrapWithComponent = <A extends React.FC<ExpectedAny> | keyof React.JSX.IntrinsicElements>(
    props: {
        /**
         * React component to wrap the children with. This component must accept children as a prop as
         * it will be passed the children prop internally.
         */
        component: A;

        /**
         * Where or not the component should wrap the children.
         */
        wrap?: boolean;

        /**
         * The children to be wrapped.
         */
        children: React.ReactNode;
    } & Omit<Utilities.Prettify<Utilities.AdvanceInferReactProps<NoInfer<A>>>, 'children' | 'wrap'>
) => React.ReactElement;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Component that can optionally wrap the children in the component passed through the
 * `component` prop. If `wrap` is false, it will return the children as is.
 */
export const WrapWithComponent: TWrapWithComponent = ({ component: Component, wrap, children, ...props }) => {
    /***** RENDER *****/
    if (wrap) {
        // @ts-ignore
        return <Component {...props}>{children}</Component>
    }

    return <>{children}</>
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
