/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import styled from 'styled-components'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TProps = {
    all?: number | string | Utilities.Falsy
    top?: number | string | Utilities.Falsy
    right?: number | string | Utilities.Falsy
    bottom?: number | string | Utilities.Falsy
    left?: number | string | Utilities.Falsy
    block?: number | string | Utilities.Falsy
    inline?: number | string | Utilities.Falsy
}

const handleProp = (property: string, value: number | string | Utilities.Falsy) => {
    if (typeof value === 'number') return `${property}: ${value}px;`
    else if (typeof value === 'string') return `${property}: ${value};`
    else return ''
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Padding is a utility component that allows you to add padding to a component without needing to create a new class or Styled component.
 */
const _Padding = styled.div<TProps>`
    ${({ all }) => handleProp('padding', all)}
    ${({ top }) => handleProp('padding-top', top)}
    ${({ right }) => handleProp('padding-right', right)}
    ${({ bottom }) => handleProp('padding-bottom', bottom)}
    ${({ left }) => handleProp('padding-left', left)}
    ${({ block }) => handleProp('padding-block', block)}
    ${({ inline }) => handleProp('padding-inline', inline)}
`

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Margin is a utility component that allows you to add margin to a component without needing to create a new class or Styled component.
 */
const _Margin = styled.div<TProps>`
    ${({ all }) => handleProp('margin', all)}
    ${({ top }) => handleProp('margin-top', top)}
    ${({ right }) => handleProp('margin-right', right)}
    ${({ bottom }) => handleProp('margin-bottom', bottom)}
    ${({ left }) => handleProp('margin-left', left)}
    ${({ block }) => handleProp('margin-block', block)}
    ${({ inline }) => handleProp('margin-inline', inline)}
`

export const Padding = Object.assign(_Padding, { Margin: _Margin })
