import { Dialog } from '@headlessui/react'
import { LightboxBody } from 'components/lightbox/body'
import { StyledIcon } from 'components/lightbox/confirmation/style'
import { Flex } from 'components/utilities/Flex'
import { Text } from 'components/utilities/Text'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LightboxConfirmation = React.FC<{
    title: string
    icon: React.FC<{ width?: number | string }>
    type: 'primary' | 'confirm' | 'error' | 'warning' | 'info'
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _LightboxConfirmation: LightboxConfirmation = ({ title, icon, type, children }) => {
    /***** RENDER HELPERS *****/
    const iconTypeProps = {
        [`$${type}`]: true
    }

    /***** RENDER *****/
    return (
        <LightboxBody ignoreLoading>
            <Flex direction='column' gap={10}>
                <StyledIcon as={icon} width={42} aria-hidden='true' {...iconTypeProps} />
                <Dialog.Title>
                    <Text as='h3' align-center color='text--300'>
                        {title}
                    </Text>
                </Dialog.Title>
                {children}
            </Flex>
        </LightboxBody>
    )
}
