import { FrameStyles as S } from 'components/frame/style'
import type { User } from 'models/account'
import { Sidenav } from 'nxui/src'
import type React from 'react'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Frame = React.FC<{
    className?: string
    company: {
        src: string | null
        alt: string
        application: string
        navigate: () => void
    }
    nav: Array<React.ReactNode>
    profile: {
        user?: User
        account: {
            name: string
            company: string
            account_number: number | string
            role: {
                name: string
            }
        }
        redirect: () => void
    }
    children: React.ReactNode
    support: unknown
    shop: unknown
    switchAcc: unknown
    logout: () => void
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Frame component used to wrap the application. This is intended to be used for the user application
 * as it provides a sidenav and a content area.
 */
export const Frame: Frame = ({ className, children, company, nav, profile, support, shop, switchAcc, logout }) => {
    /***** RENDER *****/
    return (
        <S.Base className={className}>
            <Sidenav shop={shop} support={support} company={company} nav={nav} profile={profile} switchAcc={switchAcc} logout={logout} />
            {children}
        </S.Base>
    )
}
