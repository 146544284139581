import { createContext, useContext } from 'react'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BuubleContext = {
    actionOnclickRef: React.MutableRefObject<(() => void) | undefined>
}

export const BubbleContext = createContext<BuubleContext | null>(null)

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useBubbleContext = () => {
    /***** HOOKS *****/
    const context = useContext(BubbleContext)
    if (!context) {
        throw new Error('useBubbleContext must be used within a BubbleContext.Provider')
    }

    /***** HOOK RESULTS *****/
    return {
        /**
         * Ref to be used so that the bubble action can be triggered from anywhere.
         */
        ref: (node: HTMLElement) => {
            context.actionOnclickRef.current = () => node.click()
        }
    }
}
