/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import Badges from 'containers/domains/components/badges'
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { domainAPI, useToggleAutoRenewMutation, useToggleIdProtectionMutation } from 'api/domain'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Overview } from 'containers/domains/domains.styles'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { daysRemainsUntilExpired, isAUDomain, parseISO, parseISOToLocalString } from 'helpers/utils'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/

interface Props {
    meta?: ServiceMetaPayload
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function OverviewComponent({ meta = { page: '1' } }: Props) {
    /***** HOOKS *****/
    const { id } = useParams()
    const domainId = Number(id)

    /***** QUERIES *****/
    const [toggleAutoRenew, { isLoading: isTogglingAutoRenew }] = useToggleAutoRenewMutation()
    const [toggleIdProtection, { isLoading: isTogglingIdProtection }] = useToggleIdProtectionMutation()
    const { domain } = domainAPI.endpoints.domainList.useQueryState(meta, {
        selectFromResult: ({ data }) => ({
            domain: data?.data.find((domainItem) => domainItem.id === domainId)
        })
    })

    /***** RENDER HELPERS *****/
    function renderExpiry() {
        if (!domain || !domain?.meta.expiry) {
            return ''
        }

        const now = DateTime.now()
        const expiryDate = parseISO(domain.meta.expiry)
        const expiryDiff = expiryDate.diff(now, 'days').toObject().days

        if (domain.status === 'active' && expiryDiff && expiryDiff > -1) {
            return `(${daysRemainsUntilExpired(domain.meta.expiry)})`
        }

        return ''
    }

    /***** RENDER *****/
    return (
        <Overview.Base
            header={{
                element: (
                    <Overview.Header>
                        <Overview.Service>
                            <Overview.Name>{domain?.domain}</Overview.Name>
                        </Overview.Service>
                        <Badges domain={domain} />
                    </Overview.Header>
                )
            }}
        >
            {domain?.status === 'active' && (
                <Overview.Info>
                    <Overview.Column>
                        <Overview.Row>
                            <span>Registration Date</span>
                            <span>{domain?.meta?.registered_at ? parseISOToLocalString(domain?.meta?.registered_at) : null}</span>
                        </Overview.Row>
                    </Overview.Column>
                    <Overview.Column>
                        <Overview.Row>
                            <span>Expiration Date</span>
                            <span>
                                {domain?.meta.expiry ? parseISOToLocalString(domain.meta.expiry) : null}
                                {renderExpiry()}
                            </span>
                        </Overview.Row>
                    </Overview.Column>
                    <Overview.Column>
                        <Overview.Row>
                            <span>Auto-Renew</span>
                            <NXUIForm.Switch
                                checked={!!domain?.meta.auto_renew}
                                disabled={domain?.status !== 'active' || isTogglingAutoRenew}
                                onClick={() => {
                                    if (domain) {
                                        toggleAutoRenew({
                                            domain: {
                                                ...domain,
                                                meta: {
                                                    ...domain?.meta,
                                                    auto_renew: !domain?.meta.auto_renew
                                                }
                                            },
                                            meta
                                        })
                                    }
                                }}
                            />
                        </Overview.Row>
                    </Overview.Column>
                    <Overview.Column>
                        <Overview.Row>
                            <span>ID Protection</span>
                            {isAUDomain(domain?.domain) ? (
                                <span>N/A</span>
                            ) : (
                                <NXUIForm.Switch
                                    checked={!!domain?.meta.id_protection}
                                    disabled={domain?.status !== 'active' || isTogglingIdProtection}
                                    onClick={() => {
                                        if (domain) {
                                            toggleIdProtection({
                                                domain: {
                                                    ...domain,
                                                    meta: {
                                                        ...domain?.meta,
                                                        id_protection: !domain?.meta.id_protection
                                                    }
                                                },
                                                meta
                                            })
                                        }
                                    }}
                                />
                            )}
                        </Overview.Row>
                    </Overview.Column>
                </Overview.Info>
            )}
        </Overview.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { OverviewComponent as Overview }
