import { baseApi } from 'api/base'
import { loginServiceEndpoint } from 'api/service/useLoginServiceMutation'
import { Invoice } from 'models/billing'
import {
    BillingCycle,
    CancelService,
    ChangePlanPayload,
    ChangePlanPreview,
    LoginUrl,
    ModuleMeta,
    PendingServiceCancellation,
    PlanChangingStatus,
    Service,
    ServiceCancellationCheck
} from 'models/service'
import { IProduct } from 'models/shop/product'
import { servicesEndpoint } from './useServicesQuery'

/**********************************************************************************************************
 *   SERVICE API
 **********************************************************************************************************/
export const serviceAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        services: servicesEndpoint(builder),
        loginService: loginServiceEndpoint(builder),
        service: builder.query<Service, number | undefined>({
            query: (id) => `client/services/${id}`,
            transformResponse: (response: { data: Service }) => response.data,
            providesTags: ['Service']
        }),
        availablePlans: builder.query<{ plan_paths: Array<IProduct>; current_product: IProduct }, number>({
            query: (id) => `client/services/${id}/change-plan`,
            transformResponse: (response: { data: { plan_paths: Array<IProduct>; current_product: IProduct } }) => response.data,
            providesTags: ['Available-Plans']
        }),
        changePlanStatus: builder.query<PlanChangingStatus, number>({
            query: (id) => `client/services/${id}/change-plan/status`,
            transformResponse: (response: { data: PlanChangingStatus }) => response.data,
            providesTags: ['Change-Plan-Status']
        }),
        changePlanPreview: builder.mutation<ChangePlanPreview, ChangePlanPayload>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/change-plan`,
                method: 'POST',
                body: {
                    ...rest,
                    preview: true
                }
            }),
            transformResponse: (response: { data: ChangePlanPreview }) => response.data
        }),
        changePlan: builder.mutation<Invoice, ChangePlanPayload>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/change-plan`,
                method: 'POST',
                body: {
                    ...rest,
                    preview: false
                }
            }),
            transformResponse: (response: { data: Invoice }) => response.data,
            invalidatesTags: ['Change-Plan-Status', 'Service', 'Account-Credit', 'Invoices']
        }),
        cancelChangePlanRequest: builder.mutation<[], number>({
            query: (id) => ({
                url: `client/services/${id}/change-plan`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Change-Plan-Status', 'Service']
        }),
        renewPrice: builder.query({
            query: (id) => `client/services/${id}/renew`,
            transformResponse: (response: { data: Array<BillingCycle> }) => response.data,
            providesTags: ['Renew-Price']
        }),
        renewService: builder.mutation<Invoice, { id: number; billing_cycle_id: number }>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/renew`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (response: { data: Invoice }) => response.data
        }),
        billingCycle: builder.query<Array<BillingCycle>, number>({
            query: (id) => `client/services/${id}/billing-cycle`,
            transformResponse: (response: { data: Array<BillingCycle> }) => response.data,
            providesTags: ['Billing-Cycle']
        }),
        changeBillingCycle: builder.mutation<Service, { id: number; billing_cycle_id: number }>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/billing-cycle`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: [{ type: 'Services', id: 'LIST' }]
        }),
        loginURL: builder.query<string, number>({
            query: (id) => `client/services/${id}/login`,
            transformResponse: (response: { data: LoginUrl }) => response.data.url
        }),
        moduleMeta: builder.query<ModuleMeta, number>({
            query: (id) => `client/services/${id}/module/meta`,
            transformResponse: (response: { data: ModuleMeta }) => response.data,
            providesTags: ['Module-Meta']
        }),
        changePassword: builder.mutation<[], { id: number; password: string }>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/module/change-password`,
                method: 'POST',
                body: rest
            })
        }),
        pendingServiceCancellation: builder.query<PendingServiceCancellation | [], number>({
            query: (id) => `client/services/${id}/cancel`,
            transformResponse: (response: { data: PendingServiceCancellation | [] }) => response.data,
            providesTags: ['Cancellation-Requests']
        }),
        checkCancelableService: builder.query<ServiceCancellationCheck, number>({
            query: (id) => `client/services/${id}/cancel/check`,
            transformResponse: (response: { data: ServiceCancellationCheck }) => response.data
        }),
        cancelService: builder.mutation<[], CancelService & { id: number }>({
            query: ({ id, ...rest }) => ({
                url: `client/services/${id}/cancel`,
                method: 'POST',
                body: rest
            }),
            invalidatesTags: (_, __, args) => {
                return ['Cancellation-Requests', { type: 'Services', id: args.id }]
            }
        }),
        deleteCancelRequest: builder.mutation<[], number>({
            query: (id) => ({
                url: `client/services/${id}/cancel`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, __, id) => {
                return ['Cancellation-Requests', { type: 'Services', id }]
            }
        })
    })
})

export const {
    useServicesQuery,
    usePrefetch,
    useServiceQuery,
    useAvailablePlansQuery,
    useChangePlanStatusQuery,
    useChangePlanMutation,
    useChangePlanPreviewMutation,
    useCancelChangePlanRequestMutation,
    useRenewPriceQuery,
    useRenewServiceMutation,
    useBillingCycleQuery,
    useChangeBillingCycleMutation,
    useLoginURLQuery,
    useModuleMetaQuery,
    useChangePasswordMutation,
    usePendingServiceCancellationQuery,
    useCheckCancelableServiceQuery,
    useCancelServiceMutation,
    useDeleteCancelRequestMutation
} = serviceAPI
