/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import toast from 'react-hot-toast'

/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { TAG_TYPES, baseApi } from 'api/base'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ServiceMetaPayload } from 'api/service/types'
import { Links, Message, MetaResponse, Response } from 'models/app'
import { Invoice } from 'models/billing'
import {
    ContactsResponse,
    DNS,
    DNSModeTypePayload,
    DNSRecord,
    Domain,
    DomainRedirect,
    DomainRenewal,
    EligibilityDetail,
    EmailRedirectsResponse,
    PresetResponse,
    TCreateEmailRedirectPayload,
    TDeleteEmailRedirectPayload,
    TUpdateEmailRedirectPayload
} from 'models/domain'

/**********************************************************************************************************
 *   ACCOUNT API
 **********************************************************************************************************/
export const domainAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        domainList: builder.query<{ data: Array<Domain>; links: Links; meta: MetaResponse }, ServiceMetaPayload>({
            query: ({ page = 1, filter_by, sort_by, search_by }) => {
                const handleFilter = (filter_by?: Array<string>) => {
                    if (!filter_by) return ''

                    return (
                        filter_by
                            //combine each filter into a single query string
                            .reduce((acc, filter) => acc + `&filter_by[]=${filter}`, '')
                    )
                }

                const sortBy = sort_by ? `&sort_by=${sort_by}` : ''
                const filterBy = handleFilter(filter_by)
                const searchBy = search_by ? `&search_by=${search_by.key},~,${search_by.value}` : ''

                return `client/domains?page=${page}${sortBy}${filterBy}${searchBy}`
            },
            providesTags: ['Domains']
        }),
        domain: builder.query<Domain, number>({
            query: (id) => `client/domains/${id}`,
            transformResponse: (response: { data: Domain }) => response.data,
            providesTags: ['Domain']
        }),
        eppCode: builder.query<string, number>({
            query: (id) => `client/domains/${id}/epp-code`,
            transformResponse: (response: { data: { password: string } }) => response.data.password,
            providesTags: ['EPP-Code']
        }),
        updateEPPCode: builder.mutation<void, { id: number; epp: string }>({
            query: ({ id, ...rest }) => ({
                url: `client/domains/${id}/epp-code`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['EPP-Code', 'Domains']
        }),
        toggleAutoRenew: builder.mutation<Domain, { domain: Domain; meta: ServiceMetaPayload }>({
            query: ({ domain }) => ({
                url: `client/domains/${domain.id}/auto-renew`,
                method: 'POST'
            }),
            async onQueryStarted({ domain, meta }, { dispatch, queryFulfilled }) {
                const domainListResult = dispatch(
                    domainAPI.util.updateQueryData('domainList', meta, (draft) => {
                        draft.data[draft.data.findIndex((domainItem) => domainItem.id === domain.id)] = domain
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    domainListResult.undo()
                }
            }
        }),
        toggleIdProtection: builder.mutation<Domain, { domain: Domain; meta: ServiceMetaPayload }>({
            query: ({ domain }) => ({
                url: `client/domains/${domain.id}/id-protection`,
                method: 'POST'
            }),
            async onQueryStarted({ domain, meta }, { dispatch, queryFulfilled }) {
                const domainResult = dispatch(
                    domainAPI.util.updateQueryData('domainList', meta, (draft) => {
                        draft.data[draft.data.findIndex((domainItem) => domainItem.id === domain.id)] = domain
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    domainResult.undo()
                }
            },
            invalidatesTags: ['Domain-Contacts']
        }),
        toggleTransferLock: builder.mutation<Domain, { domain: Domain; meta: ServiceMetaPayload }>({
            query: ({ domain }) => ({
                url: `client/domains/${domain.id}/transfer-lock`,
                method: 'POST'
            }),
            async onQueryStarted({ domain, meta }, { dispatch, queryFulfilled }) {
                const domainResult = dispatch(
                    domainAPI.util.updateQueryData('domainList', meta, (draft) => {
                        draft.data[draft.data.findIndex((domainItem) => domainItem.id === domain.id)] = domain
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    domainResult.undo()
                }
            }
        }),
        eligibilityDetails: builder.query<Array<EligibilityDetail>, number>({
            query: (id) => `client/domains/${id}/eligibility-details`,
            transformResponse: (response: { data: Array<EligibilityDetail> }) => response.data,
            providesTags: ['Eligibility']
        }),
        updateEligibility: builder.mutation<void, { id: number; eligibility_information: Array<{ field_name: string; field_value: string }> }>({
            query: ({ id, ...rest }) => ({
                url: `client/domains/${id}/eligibility-details`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Eligibility', 'Domains']
        }),
        dnsRecords: builder.query<DNS, number>({
            query: (id) => `client/domains/${id}/dns/records`,
            transformResponse: (response: { data: DNS }) => response.data,
            providesTags: ['Records']
        }),
        addDNSRecords: builder.mutation<
            Response | { messages: Array<Message> },
            { id: number; records: Array<DNSRecord>; successMessage?: string; replacements?: Record<string, string> | object }
        >({
            query: ({ id, ...rest }) => ({
                url: `client/domains/${id}/dns/records`,
                method: 'POST',
                body: rest
            }),
            async onQueryStarted({ id, ...rest }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const success = {
                        detail: rest.successMessage ?? 'Successfully Added DNS record',
                        type: 'success'
                    }

                    // @ts-ignore
                    toast.custom(success, {
                        duration: 8000
                    })

                    if (rest.replacements) return

                    dispatch(
                        domainAPI.util.updateQueryData('dnsRecords', id, (draft) => {
                            Object.assign(draft, rest)
                        })
                    )
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    //to roll this back, we'd need to undo the mutation by making a new request with the original records
                    //for now, if there is a failure, we'll just refetch data to show new data as well as the error message
                    dispatch(domainAPI.util.invalidateTags(['Records']))

                    const { data, messages } = error?.error?.data ?? {}

                    if (messages?.some?.(({ detail }: { detail: string }) => detail === 'Failed to update DNS records')) {
                        const errorMessage = {
                            type: 'ERROR',
                            detail: (
                                <>
                                    An error occurred while adding the following DNS records:
                                    {data.map((message: string) => (
                                        <p key={message}>{message}</p>
                                    ))}
                                </>
                            )
                        }

                        // @ts-ignore
                        toast.error(errorMessage, {
                            duration: 12000
                        })
                    }

                    return
                }
            },
            invalidatesTags: ['Domain-Presets', 'Records']
        }),
        updateNameservers: builder.mutation<
            { dns_config: string; nameservers: Array<string> },
            { id: number; dns_config: Omit<DNSModeTypePayload, 'URL & Email Forwarding'> | 'forwarding'; nameservers?: Array<string> }
        >({
            query: ({ id, ...rest }) => ({
                url: `client/domains/${id}/nameservers`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (response: { data: { dns_config: string; nameservers: Array<string> } }) => response.data,
            invalidatesTags: ['Records']
        }),
        domainRenewal: builder.query<DomainRenewal, number>({
            query: (id) => `client/domains/${id}/renewal`,
            transformResponse: (response: { data: DomainRenewal }) => response.data,
            providesTags: ['Domain-Renewal']
        }),
        renewDomain: builder.mutation<Invoice, { id: number; billing_cycle_id: number; promo_code?: string }>({
            query: ({ id, ...rest }) => ({
                url: `client/domains/${id}/renewal`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (response: { data: Invoice }) => response.data,
            invalidatesTags: ['Domain-Renewal']
        }),
        domainContacts: builder.query<ContactsResponse, number>({
            query: (id) => `client/domains/${id}/contacts`,
            transformResponse: (response: { data: ContactsResponse }) => response.data,
            providesTags: ['Domain-Contacts']
        }),
        updateDomainContacts: builder.mutation<[], { id: number; contacts: ContactsResponse }>({
            query: ({ id, contacts }) => ({
                url: `client/domains/${id}/contacts`,
                method: 'PUT',
                body: { contacts }
            }),
            invalidatesTags: ['Domain-Contacts']
        }),
        dnsPresets: builder.query<Array<PresetResponse>, number>({
            query: (id) => `client/domains/${id}/dns-presets`,
            transformResponse: (response: { data: PresetResponse[] }) => {
                return response.data.map((preset) => ({
                    ...preset,
                    /**
                     * Ensure that merge fields are always returned without '{{' and '}}'
                     */
                    input_fields: preset.input_fields.map((field) => field.replaceAll(/[{}]/g, ''))
                }))
            },
            providesTags: ['Domain-Presets']
        }),

        /**********************************************************************************************************
         *   EMAIL REDIRECT ENDPOINTS
         **********************************************************************************************************/
        createEmailRedirect: builder.mutation<EmailRedirectsResponse, TCreateEmailRedirectPayload>({
            query: ({ domainId, ...rest }) => ({
                url: `client/domains/${domainId}/dns/mail-forwarders`,
                method: 'POST',
                body: rest
            }),
            invalidatesTags: [TAG_TYPES.EMAIL_REDIRECTS]
        }),

        emailRedirects: builder.query<EmailRedirectsResponse, number>({
            query: (id) => `client/domains/${id}/dns/mail-forwarders`,
            transformResponse: (response: { data: EmailRedirectsResponse }) => response.data,
            providesTags: [TAG_TYPES.EMAIL_REDIRECTS]
        }),

        deleteEmailRedirect: builder.mutation<unknown, TDeleteEmailRedirectPayload>({
            query: ({ domainId, recordId }) => ({
                url: `client/domains/${domainId}/dns/mail-forwarders/${recordId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [TAG_TYPES.EMAIL_REDIRECTS]
        }),

        updateEmailRedirect: builder.mutation<unknown, TUpdateEmailRedirectPayload>({
            query: ({ domainId, recordId, ...rest }) => ({
                url: `client/domains/${domainId}/dns/mail-forwarders/${recordId}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: [TAG_TYPES.EMAIL_REDIRECTS]
        }),

        /**********************************************************************************************************
         *   EMAIL REDIRECT ENDPOINTS
         **********************************************************************************************************/
        createDomainRedirect: builder.mutation<unknown, DomainRedirect.TCreatePayload>({
            query: ({ domainId, ...rest }) => ({
                url: `client/domains/${domainId}/dns/url-redirects`,
                method: 'POST',
                body: rest
            }),
            invalidatesTags: [TAG_TYPES.DOMAIN_REDIRECTS]
        }),

        domainRedirects: builder.query<DomainRedirect.TResponse, number>({
            query: (id) => `client/domains/${id}/dns/url-redirects`,
            transformResponse: (response: { data: DomainRedirect.TResponse }) => response.data,
            providesTags: [TAG_TYPES.DOMAIN_REDIRECTS]
        }),

        deleteDomainRedirect: builder.mutation<unknown, unknown>({
            query: ({ domainId, recordId }) => ({
                url: `client/domains/${domainId}/dns/url-redirects/${recordId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [TAG_TYPES.DOMAIN_REDIRECTS]
        }),

        updateDomainRedirect: builder.mutation<unknown, DomainRedirect.TUpdatePayload>({
            query: ({ domainId, recordId, ...rest }) => ({
                url: `client/domains/${domainId}/dns/url-redirects/${recordId}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: [TAG_TYPES.DOMAIN_REDIRECTS]
        })
    })
})

export const {
    usePrefetch,
    useDomainListQuery,
    useDomainQuery,
    useDnsRecordsQuery,
    useEppCodeQuery,
    useUpdateEPPCodeMutation,
    useToggleAutoRenewMutation,
    useToggleIdProtectionMutation,
    useToggleTransferLockMutation,
    useEligibilityDetailsQuery,
    useUpdateEligibilityMutation,
    useAddDNSRecordsMutation,
    useUpdateNameserversMutation,
    useDomainRenewalQuery,
    useRenewDomainMutation,
    useDomainContactsQuery,
    useUpdateDomainContactsMutation,
    useDnsPresetsQuery
} = domainAPI
