import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { useBubbleContext } from 'components/bubble/context'
import { BubbleStyles as S } from 'components/bubble/style'
import type { TOnClickButtonProps } from 'components/buttons/base'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BubbleActionType = React.FC<{
    children: React.ReactNode
    onClick: TOnClickButtonProps['onClick']
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _BubbleAction: BubbleActionType = ({ children, onClick }) => {
    /***** RENDER *****/
    return (
        <S.ActionButton color='alternate' onClick={onClick}>
            {children} <ArrowRightIcon />
        </S.ActionButton>
    )
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BubbleActionLinkType = React.FC<{
    children: React.ReactNode
    to: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _BubbleActionLink: BubbleActionLinkType = ({ children, to }) => {
    /***** HOOKS *****/
    const { ref } = useBubbleContext()

    /***** RENDER *****/
    return (
        <S.ActionLink ref={ref} color='alternate' className='Bubble__action' to={to}>
            {children} <ArrowRightIcon className='icon' />
        </S.ActionLink>
    )
}
