/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Transition } from '@headlessui/react'
import React, { Fragment, useMemo, useRef } from 'react'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { LightboxBody } from './body'
import { LightboxFooter } from './footer'
import { LightboxHeader } from './header'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { LightboxContext } from './utilities'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { _LightboxConfirmation } from 'components/lightbox/confirmation'
import { _LightboxConfirmationAction } from 'components/lightbox/confirmation/action'
import { _LightboxConfirmationDescription } from 'components/lightbox/confirmation/description'
import { LightboxStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLightbox = React.FC<{
    isOpen: boolean
    children: React.ReactNode
    onClose: () => void
    className?: string
    isLoading?: boolean
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Lightbox: TLightbox = ({ children, isOpen, onClose, className, isLoading }) => {
    /***** HOOKS *****/
    const contentRef = useRef(null)
    const contextValue = useMemo(() => ({ isLoading, onClose }), [isLoading, onClose])

    /***** RENDER *****/
    return (
        <div>
            <S.GlobalLightboxStyle />
            <S.TransitionRoot show={isOpen}>
                <S.Dialogue className={className} initialFocus={contentRef} onClose={onClose}>
                    <S.InternalWrapper>
                        <Transition.Child
                            as={Fragment}
                            enter='nxLightbox__transition--enter'
                            enterFrom='nxLightbox__transition--enterFrom'
                            enterTo='nxLightbox__transition--enterTo'
                            leave='nxLightbox__transition--leave'
                            leaveFrom='nxLightbox__transition--leaveFrom'
                            leaveTo='nxLightbox__transition--leaveTo'
                        >
                            <S.TransitionChildInternal />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <S.HiddenSpan>&#8203;</S.HiddenSpan>

                        <Transition.Child
                            as={Fragment}
                            enter='nxLightboxContent__transition--enter'
                            enterFrom='nxLightboxContent__transition--enterFrom'
                            enterTo='nxLightboxContent__transition--enterTo'
                            leave='nxLightboxContent__transition--leave'
                            leaveFrom='nxLightboxContent__transition--leaveFrom'
                            leaveTo='nxLightboxContent__transition--leaveTo'
                        >
                            <S.ChildrenWrapper ref={contentRef}>
                                <LightboxContext.Provider value={contextValue}>{children}</LightboxContext.Provider>
                            </S.ChildrenWrapper>
                        </Transition.Child>
                    </S.InternalWrapper>
                </S.Dialogue>
            </S.TransitionRoot>
        </div>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** EXPORTS *****/
export const Lightbox = Object.assign(_Lightbox, {
    Footer: LightboxFooter,
    Body: LightboxBody,
    Header: LightboxHeader,
    Confirmation: Object.assign(_LightboxConfirmation, {
        Description: _LightboxConfirmationDescription,
        Actions: _LightboxConfirmationAction
    }),
    Styles: S
})
