/* eslint-disable indent */
/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { CheckIcon } from '@heroicons/react/24/solid'
import { Badge } from '../../badge'

import styled, { css } from 'styled-components'
import * as Theme from '../../../theme/theme'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
export const Services = {
    Product: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 16px;
        border: 1px solid ${(props) => (props.important ? Theme.Functions.hexToRGBA(props.theme[`primary--100`], 0.35) : props.theme['border--100'])};
    `
}

export const Product = {
    Base: styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
            flex-direction: column;
        }
    `,
    Title: styled.h2`
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        color: ${(props) => props.theme['text--300']};
        ${Theme.Functions.title()};
        font-size: 1.8rem;

        > span {
            height: 25px;
        }
    `,
    Summary: styled.div`
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        border-radius: 8px 0 0 8px;
        width: 100%;
        overflow-wrap: anywhere;
    `,
    Price: styled.h4`
        text-transform: capitalize;
        color: ${(props) => props.theme['text--200']};
        ${Theme.Functions.body()};
        font-size: 1.2rem;
        ${Theme.Functions.setFont('Inter', 500)};
    `,
    Description: styled.p`
        margin-top: 20px;
        color: ${(props) => props.theme['text--300']};
        ${Theme.Functions.body(undefined, 500)};
    `,
    Action: styled.div`
        width: 100%;
        padding: 20px 0 0;

        > button {
            width: fill-available;
            margin: 0;
        }
    `,
    Features: {
        List: styled.ul`
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 0;
            justify-content: center;
            border-radius: 0 8px 8px 0;
            padding: 20px;
            gap: 10px;
            background-color: ${(props) => props.theme['background--100']};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                justify-content: flex-start;
            }
        `,
        Item: styled.li`
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.body()};
        `,
        Description: styled.p`
            color: ${(props) => props.theme['text--300']};
            ${Theme.Functions.body()};

            > span {
                ${Theme.Functions.body(undefined, 700)};
            }
        `,
        Icon: styled(CheckIcon)`
            width: 24px;
            min-width: 24px;
            margin-right: 10px;
            color: ${(props) => props.theme['confirm--100']};
        `
    },
    Badge: styled(Badge)`
        padding: 0 6px;
        height: 23px;
        ${Theme.Functions.body()};
    `,
    Dock: {
        Base: styled.div`
            position: relative;
            visibility: hidden;
            overflow: hidden;
            max-height: 0;
            border-top: 1px solid ${(props) => props.theme[`transparent`]};
            border-radius: 0 0 16px 16px;
            background-color: ${(props) => props.theme[`background--000`]};
            transition: max-height 250ms 0s, border-color 0s 200ms, visibility 0s 200ms;

            ${({ open, height }) =>
                open &&
                css`
                    visibility: visible;
                    max-height: ${height + 2}px;
                    border-color: ${(props) => props.theme[`border--100`]};
                    transition: max-height 250ms 0s, border-color 0s 0ms;
                `}
        `,
        Wrapper: styled.div`
            position: relative;
            width: 100%;
        `,
        Title: styled.h2`
            padding: 15px 20px 0px;
            width: 100%;
            font-size: 1.8rem;
            color: ${(props) => props.theme['text--200']};
            ${Theme.Functions.setFont('Inter', 600)};
        `,
        Actions: styled.div`
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-radius: 0 0 8px 8px;
            background-color: ${(props) => props.theme[`background--100`]};

            button {
                margin: 0;

                &:first-of-type {
                    margin-right: auto;
                }
            }
        `,
        Close: styled.div`
            position: absolute;
            top: 5px;
            right: 5px;

            svg {
                cursor: pointer;
                width: 18px;
                color: ${(props) => props.theme[`error--100`]};
            }
        `
    }
}
