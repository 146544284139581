/***** BASE IMPORTS *****/
import { Store, useStore } from '@tanstack/react-store'

/***** TYPE DEFINITIONS *****/
type TViewport = keyof typeof MediaStore.mediaResolutions
type Match = Array<TViewport> | undefined
type DefaultReturn<TMatch extends Match> = TMatch extends undefined ? TViewport : boolean
type Select<TMatch extends Match, TSelected> = (result: DefaultReturn<TMatch>) => TSelected

type TUseAppViewport = <TMatch extends Match, TSelected = DefaultReturn<TMatch>>(match?: TMatch, select?: Select<TMatch, TSelected>) => TSelected

/***** COMPONENT START *****/
class MediaStore {
    private static store = new Store<{ viewport: TViewport }>({ viewport: 'xs' })

    public constructor() {
        MediaStore.setInitialViewport()
        MediaStore.initializeMediaListeners()
    }

    /**
     * Hook to get the current viewport of the application. This hook will only cause a rerender when the viewport crosses
     * a breakpoint rather than on resize events. In addition, provides a selector function which can be used to select
     * a specific value based on the current viewport.
     */
    public useAppViewport: TUseAppViewport = (match?: Match, select = MediaStore.defaultSelector) => {
        return useStore(MediaStore.store, ({ viewport }) => {
            return select(!match ? (viewport as ExpectedAny) : match.includes(viewport))
        })
    }

    private static defaultSelector = (match: boolean | TViewport): ExpectedAny => match

    private static setInitialViewport = () => {
        // Set initial viewport
        Object.entries(MediaStore.mediaResolutions).forEach(([viewport, resolution]) => {
            if (window.matchMedia(resolution).matches) {
                MediaStore.store.setState(() => ({ viewport: viewport as TViewport }))
            }
        })
    }

    private static initializeMediaListeners = () => {
        // Listen for media changes
        Object.entries(MediaStore.mediaResolutions).forEach(([viewport, resolution]) => {
            if (typeof window.matchMedia(resolution).addListener === 'function') {
                window.matchMedia(resolution).addListener(MediaStore.handleMediaChange(viewport as TViewport))
            } else {
                window.matchMedia(resolution).addEventListener('change', MediaStore.handleMediaChange(viewport as TViewport))
            }
        })
    }

    private static handleMediaChange = (viewport: TViewport) => (e: MediaQueryListEvent) => {
        if (e.matches) {
            MediaStore.store.setState(() => ({ viewport }))
        }
    }

    public static mediaResolutions = {
        xs: '(max-width: 380px)',
        sm: '(min-width: 380px) and (max-width: 640px)',
        md: '(min-width: 640px) and (max-width: 768px)',
        lg: '(min-width: 768px) and (max-width: 1024px)',
        xl: '(min-width: 1024px) and (max-width: 1280px)',
        '2xl': '(min-width: 1280px) and (max-width: 1536px)',
        '3xl': '(min-width: 1536px) and (max-width: 2160px)',
        '4xl': '(min-width: 2160px)'
    } as const
}

export const { useAppViewport } = new MediaStore()
