/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { css } from 'styled-components'
import { EFontWeights } from '../models/generic'

const Functions = {
    hexToRGBA(hex, alpha = 1) {
        const hexValue = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i) // breakapart and match each 2 char hex value.

        return hexValue ? `rgba(${[parseInt(hexValue[1], 16), parseInt(hexValue[2], 16), parseInt(hexValue[3], 16), alpha]})` : null
    },

    reset() {
        return css`
            all: unset;
        `
    },

    randomColor() {
        return `hsla(${Math.random() * 360}, 80%, 50%, 0.5)`
    },

    mediaBreakUp(breakpoint) {
        return `@media only screen and (min-width: ${breakpoint}px)`
    },

    mediaBreakDown(breakpoint) {
        return `@media only screen and (max-width: ${breakpoint}px)`
    },

    mediaBreakBetween(breakpointA, breakpointB) {
        return `@media only screen and (min-width: ${breakpointA}px) and (max-width: ${breakpointB - 0.02}px)`
    },

    spaceX(v) {
        return css`
            > :not([hidden]) ~ :not([hidden]): {
                margin-right: calc(0px * ${v});
                margin-left: calc(0px * calc(1 - ${v}));
            }
        `
    },

    spaceY(v) {
        return css`
            > :not([hidden]) ~ :not([hidden]): {
                margin-top: calc(0px * calc(1 - ${v}));
                margin-bottom: calc(0px * ${v});
            }
        `
    },

    outline(x) {
        switch (x) {
            case 'none':
            default:
                return css`
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                `
        }
    },

    srOnly() {
        return css`
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
        `
    },

    inset() {
        return css`
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        `
    },

    hideScrollbar() {
        return css`
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        `
    },

    /**********************************************************************************************************
     *   ANIMATIONS
     **********************************************************************************************************/
    animation(property, duration) {
        return css`
            transition-property: ${property};
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: ${duration ? duration : 150}ms;
        `
    },

    /**********************************************************************************************************
     *   FLEX
     **********************************************************************************************************/
    flex(flex, align, justify, direction) {
        return css`
            display: ${flex};
            align-items: ${align};
            justify-content: ${justify};
            flex-direction: ${direction};
        `
    },

    /**********************************************************************************************************
     *   FONTS
     **********************************************************************************************************/
    setBaseLine() {
        return css`
            text-size-adjust: 100%;
            font-size: 62.5%;
            line-height: 1.5;
        `
    },

    normalizeText() {
        return css`
            text-decoration: none;
            line-height: 1.5;
            white-space: normal;
        `
    },

    setFont(font, weight = 400, style = 'normal') {
        return css`
            font-family: ${font};
            font-style: ${style};
            font-weight: ${weight};
        `
    },

    setExternalFont(font, weight = 400, style = 'normal', url) {
        return css`
            font-family: ${font};
            font-style: ${style};
            font-weight: ${weight};
            src: ${url};
        `
    },

    /**********************************************************************************************************
     *   TYPEOGRAPHY
     **********************************************************************************************************/
    heading() {
        return css`
            margin-bottom: 15px;
            white-space: normal;
            line-height: 5rem;
            font-size: 4rem;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: ${EFontWeights.Bold};
        `
    },

    subheading() {
        return css`
            margin-bottom: 5px;
            white-space: normal;
            line-height: 3.5rem;
            letter-spacing: -0.05rem;
            font-size: 2.8rem;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: ${EFontWeights.Semibold};
        `
    },

    title(size = '2.2rem', weight = EFontWeights.Semibold) {
        return css`
            line-height: 1.5;
            letter-spacing: -0.05rem;
            white-space: normal;
            margin-bottom: 5px;
            font-size: ${size};
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: ${weight};
        `
    },

    body(size = '1.4rem', weight = EFontWeights.Normal) {
        return css`
            line-height: 1.5;
            letter-spacing: -0.025rem;
            white-space: normal;
            font-size: ${size};
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: ${weight};
        `
    },

    caption(size = '1.2rem', weight = EFontWeights.Semibold) {
        return css`
            line-height: 1.5;
            white-space: normal;
            text-align: unset;
            font-size: ${size};
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: ${weight};
        `
    }
}

export { Functions }

