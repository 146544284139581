import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { TTagTypes } from 'api/base'
import Yup from 'helpers/yup'
import { DateTime } from 'luxon'
import { RoleSchema } from 'models/schemas/account/role'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TEndpointBuilder = EndpointBuilder<BaseQueryFn, TTagTypes, 'api'>
export type PendingAdditionalUser = ReturnType<typeof validateSync>

/**********************************************************************************************************
 *   SCHEMA DEFINITION
 **********************************************************************************************************/
const schema = Yup.object({
    data: Yup.array()
        .of(
            Yup.object({
                id: Yup.number().required(),
                token: Yup.string().required(),
                account_id: Yup.number().required(),
                email: Yup.string().required(),
                is_approved: Yup.number().oneOf([0, 1]).required(),
                created_at: Yup.string().required().nullable(),
                updated_at: Yup.string().nullable().required(),
                access_expiry: Yup.string().required().nullable(),
                role_id: Yup.number().required(),
                role: RoleSchema.required()
            })
        )
        .required()
})

const validateSync = (value: unknown) => {
    return schema.validateSync(value).data.map(({ access_expiry, created_at, ...rest }) => ({
        ...rest,
        access_expiry: access_expiry ? DateTime.fromSQL(access_expiry) : undefined,
        created_at: created_at ? DateTime.fromSQL(created_at) : undefined
    }))
}

/**********************************************************************************************************
 *   ENDPOINT
 **********************************************************************************************************/
export const pendingAdditionalUsers = (builder: TEndpointBuilder) =>
    builder.query<PendingAdditionalUser, void>({
        query: () => 'client/additional-users/pending',
        providesTags: ['Pending-Additional-Users'],
        transformResponse: validateSync
    })
