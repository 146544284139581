/* eslint-disable indent */
/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'
import * as Theme from '../../theme/theme'

import { Anchor } from '../anchor'

export const Sidenav = {
    Background: styled.div`
        z-index: 15;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        width: 100vw;
        backdrop-filter: blur(3px);
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['background--400'], '0.75')};
        ${Theme.Functions.animation('all')};

        ${({ active }) => {
            if (active)
                return css`
                    display: block;
                    opacity: 1;
                    visibility: visible;
                `
        }};

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-lg'])} {
            display: none;
        }
    `,
    Container: styled.nav`
        z-index: 16;
        position: fixed;
        left: -260px;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 260px;
        min-width: 260px;
        padding: 1.25rem;
        background-color: ${(props) => props.theme['background--100']};
        border-right: 1px solid ${(props) => props.theme['border--100']};
        ${Theme.Functions.animation(`all`)};

        ${(props) => Theme.Functions.mediaBreakUp(+props.theme['breakpoints-lg'] + 1)} {
            position: sticky;
            height: unset;
        }

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-xl'])} {
            position: sticky;
            height: unset;
        }

        ${({ active }) => {
            return active
                ? css`
                      left: 0;
                  `
                : css``
        }};
    `,
    Header: styled.header`
        position: relative;
        width: 100%;
        height: 50px;
        margin: 4px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-lg'])} {
            height: 150px;
            margin: 12px 0 0;
        }
    `,
    Title: styled.h3`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', 500)};
        ${Theme.Functions.animation('all')};
    `,
    Logo: styled.img`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    Nav: {
        List: styled.ul`
            position: relative;
            width: 100%;
            margin-bottom: auto;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-lg'])} {
                padding: 10px;
                margin-bottom: auto;
                border-radius: 16px;
                border: 1px solid ${(props) => props.theme['border--100']};
                background-color: ${(props) => props.theme['background--200']};
                overflow: auto;
            }
        `,
        Break: styled.div`
            width: 100%;
            margin: 16px 0;
            border-bottom: 1px solid ${(props) => props.theme['border--000']};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                margin: 12px 0;
            }
        `
    },
    NavItem: {
        Wrapper: styled.div`
            cursor: pointer;
            background-color: transparent;
            border-radius: 8px;
            ${Theme.Functions.animation('all')};

            svg {
                margin-right: 16px;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.animation(`all`)};
            }

            a,
            button {
                font-size: 1.4rem;
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.setFont('Inter', 500)};
                ${Theme.Functions.animation('all')};
                text-decoration: none;
                display: flex;
                align-items: center;
                height: 40px;
                width: 100%;
                padding: 0 12px;
            }

            ${({ color }) => {
                switch (color) {
                    case 'primary':
                        return css`
                            a,
                            button,
                            svg {
                                color: ${(props) => props.theme['primary--100']};
                            }
                        `

                    case 'error':
                        return css`
                            a,
                            button,
                            svg {
                                color: ${(props) => props.theme['error--100']};
                            }
                        `

                    case 'secondary':
                    default:
                        return css`
                            a,
                            button,
                            svg {
                                color: ${(props) => props.theme['text--100']};
                            }
                        `
                }
            }}

            &:hover {
                background-color: ${(props) => props.theme['background--300']};

                a,
                button,
                svg {
                    color: ${(props) => props.theme['text--200']};
                }
            }

            ${({ active }) => {
                return active
                    ? css`
                          cursor: default;
                          background-color: ${(props) => props.theme['gray--100']};

                          a,
                          button,
                          svg {
                              color: ${(props) => props.theme['text--300']};
                          }
                      `
                    : ''
            }}
        `
    },
    Profile: styled.footer`
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            margin-top: 20px;
        }
    `,
    Account: styled.footer`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    `,
    Actions: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        > button {
            width: 100%;
        }
    `,
    Logout: styled(Anchor)`
        color: ${(props) => props.theme['error--100']};
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['error--100'], '0.1')};

        .icon {
            color: ${(props) => props.theme['error--100']};
        }

        &:hover {
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['error--100'], '0.2')};
        }
    `,
    Mobile: styled.div`
        z-index: 14;
        position: fixed;
        top: 0;
        left: 0;
        height: 65px;
        width: 100%;
        background-color: ${(props) => props.theme['background--000']};
        border-bottom: 1px solid ${(props) => props.theme['border--000']};

        ${(props) => Theme.Functions.mediaBreakUp(+props.theme['breakpoints-lg'] + 1)} {
            display: none;
        }
    `
}

export const Mobile = {
    Nav: {
        List: styled.ul`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 0 16px;
        `,
        Option: styled.li`
            cursor: pointer;
            color: ${(props) => props.theme['icon--300']};
            ${Theme.Functions.animation('all')};

            &:hover {
                color: ${(props) => props.theme['text--300']};
            }

            ${({ color }) => {
                switch (color) {
                    case 'primary':
                        return css`
                            color: ${(props) => props.theme['primary--100']};

                            &:hover {
                                color: ${(props) => props.theme['primary--200']};
                            }
                        `

                    case 'error':
                        return css`
                            color: ${(props) => props.theme['error--100']};

                            &:hover {
                                color: ${(props) => props.theme['error--200']};
                            }
                        `

                    default:
                        return css`
                            color: ${(props) => props.theme['icon--300']};

                            &:hover {
                                color: ${(props) => props.theme['text--300']};
                            }
                        `
                }
            }}
        `,
        Logo: styled.li`
            width: 215px;
        `
    }
}

export const Profile = {
    Avatar: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 9999px;
        color: ${(props) => props.theme['white--100']};
        background-color: ${Theme.Functions.randomColor()};
        font-size: 1rem;
        ${Theme.Functions.setFont('Inter', 600)};
    `,
    Title: styled.p`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--300']};
        letter-spacing: -0.25px;
        ${Theme.Functions.setFont('Inter', 600)};
    `
}

export const Account = {
    Company: styled.p`
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--200']};
        letter-spacing: -0.25px;
        ${Theme.Functions.setFont('Inter', 600)};
    `,
    Role: styled.p`
        font-size: 12px;
        color: ${(props) => props.theme['text--100']};
        letter-spacing: -0.25px;
        ${Theme.Functions.setFont('Inter', 400)};
    `
}
