import Yup from 'helpers/yup'

/**********************************************************************************************************
 *   SCHEMA START
 **********************************************************************************************************/
export const AddressSchema = Yup.object({
    address1: Yup.string().required(),
    address2: Yup.string().nullable(),
    city: Yup.string().optional(),
    suburb: Yup.string().optional(),
    state: Yup.string().required(),
    postcode: Yup.string().required(),
    country: Yup.string().required(),
    company_name: Yup.string().optional(),
    organisation: Yup.string().optional()
})
